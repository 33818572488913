import React, { FocusEvent, ChangeEvent, KeyboardEvent, useCallback, useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isFirefox } from "react-device-detect";
import axios, { AxiosError } from "axios";

import { tceppsActions } from "../../redux-store/reducer/tcepps_slice";
import { tokenCreatedAction, microformInitializedAction, unmountAction, contextLoadedAction } from "../../redux-store/reducer/paymentSlice";
import { LogService, LOGERRORLEVEL } from "../../service/logService";
import PaymentService from "../../service/paymentService";
import {
  decryptParamType,
  isCybersourceTimedout,
  getCnaUsStates,
  getTimedoutDummyToken,
  getYearsFromToday,
} from "../../utils/calendarUtil";
import { TceppsTestData, isTestingApp } from "../../utils/TestLoadData";
import { isValidUSZip } from "../../utils/UsStates";

import classesMyCanon from "./MyCanon.module.css";
import { extractClientLibrary, extractDomain, parseToken } from "../../utils/utils";
import { moveUpPlaceholder, moveDownPlaceholder } from "../../utils/floatingLabel";
import { componentVarObject, ComponentVarProps } from "../../utils/ComponentConstant";

var Buffer = require("buffer/").Buffer; // note: the trailing slash is important!

let developmentEnv = false;
var creditCardMicroNumber: any;
var cvvMicroSecurityCode: any;
const cityMaxLength = isFirefox ? 36 : 42;
const ADDMESSAGE = "addmessage";
const REMOVEMESSAGE = "removmessage";

const margin20Style = {
  margin: "20px 0",
} as React.CSSProperties;
const creditCardStyle = {
  fontSize: "16px",
  fontFamily: "OpticSans",
  paddingLeft: "20px",
  marginRight: "20px",
  height: "22px",
  color: "fieldtext",
  letterSpacing: "normal",
  wordSpacing: "normal",
  lineHeight: "normal",
  textIndent: "0px",
  textShadow: "none",
  display: "inline-block",
  cursor: "text",
  // backgroundColor: "#f8f8f8",
  margin: "0em",
  borderWidth: "0px",
  width: "100%",
  borderStyle: "inset",
  // borderColor: "-internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133))",
  backgroundColor: "rgba(255, 255, 255, 0)",
  borderImage: "initial",
} as React.CSSProperties;
const flexStyles = {
  input: {
    cursor: "text",
    color: "#171719",
  },
  "::placeholder": { color: "lightslategrey" },
  ":focus": { color: "black" },
  ":disabled": { cursor: "not-allowed" },
  valid: { color: "#171719" },
  invalid: { color: "#a94442" },
  // ":hover": {
  //   "font-style": "italic",
  // },
} as React.CSSProperties;
const formElemStyle = {
  width: "100%",
  border: "0",
  backgroundColor: "#F8F8F8",
};

const toDay = new Date();
const currentMonthYear = toDay.toLocaleString("en-US", { year: "numeric" }) + toDay.toLocaleString("en-US", { month: "2-digit" });
const currentYear = toDay.toLocaleString("en-US", { year: "numeric" });

const EMPTYADDRESS1MESSAGE = "Address field cannot be empty.";
const EMPTYCITYMESSAGE = "City field cannot be empty.";
const EMPTYFIRSTNAMEMESSAGE = "First Name field cannot be empty.";
const EMPTYLASTNAMEMESSAGE = "Last Name field cannot be empty.";
const EMPTYSTATEMESSAGE = "State field cannot be empty.";
const EMPTYZIPMESSAGE = "Zipcode field cannot be empty.";
const FORMINITIZING = "Browser initializing. Please wait and retry.";
const INVALIDCARDMESSAGE = "Please select a payment option.";
const INVALIDCVVMESSAGE = "Please enter a valid CVV Number.";
const INVALIDDATEMESSAGE = "Credit card has expired.";
const INVALIDZIPMESSAGE = "Please enter correct Zipcode.";
const VALIDATIONFAILMESSAGE = "The highlighted fields above are incorrect";
// const VALIDSTATEMESSAGE = "Please enter a valid state code.";

const INVALIDAXIOSMESSAGE = "AxiosError. ";

const CREDITCARDPLACEHOLDER = "Credit Card Number *";
const CVVPLACEHOLDER = "CVV *";
const EXPMONTHPLACEHOLDER = "Expiration Month *";
const EXPYEARPLACEHOLDER = "Expiration Year *";
const FIRSTNAMEPLACEHOLDER = "First Name *";
const LASTNAMEPLACEHOLDER = "Last Name *";
const ADDR1PLACEHOLDER = "Street Address *";
const ADDR2PLACEHOLDER = "Apt or Suite";
const CITYPLACEHOLDER = "City *";
const STATEPLACEHOLDER = "State *";
const ZIPCODEPLACEHOLDER = "Zip Code *";

const moveUpClassConst = `${classesMyCanon.mycanon} ${classesMyCanon.labelMoveUp}`;
const floatingLabelClassConst = `${classesMyCanon.mycanon} ${classesMyCanon.floatingLabel}`;

export function MyCanonComponent(this: any) {
  const dispatch = useDispatch();
  const tceppsState = useSelector((state: any) => state.tcepps);
  const paymentInfo = useSelector((state: any) => state.paymentInfo);

  const [expirationMonth, setExpirationMonth] = useState("");
  const [expirationYear, setExpirationYear] = useState("");
  const [errmessage, setErrmessage] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");

  const [creditCardNumberDisabled, setCreditCardNumberDisabled] = useState(false);
  const [securityCodeDisabled, setSecurityCodeDisabled] = useState(false);
  const [expirationMonthDisabled, setExpirationMonthDisabled] = useState(false);
  const [expirationYearDisabled, setExpirationYearDisabled] = useState(false);
  const [firstNameDisabled, setFirstNameDisabled] = useState(false);
  const [lastNameDisabled, setLastNameDisabled] = useState(false);
  const [address1Disabled, setAddress1Disabled] = useState(false);
  const [address2Disabled, setAddress2Disabled] = useState(false);
  const [cityDisabled, setCityeDisabled] = useState(false);
  const [stateDisabled, setStateDisabled] = useState(false);
  const [zipDisabled, setZipDisabled] = useState(false);
  const [errmessageDisabled, setErrmessageDisabled] = useState(true);
  const [selectedCreditCard, setSelectedCreditCard] = useState("");

  const creditCardNumberRef = useRef<HTMLDivElement>(null);
  const securityCodeRef = useRef<HTMLDivElement>(null);
  const expirationMonthRef = useRef<HTMLSelectElement>(null);
  const expirationYearRef = useRef<HTMLSelectElement>(null);
  const errmessageRef = useRef<HTMLInputElement>(null);
  const firstNameRef = useRef<HTMLInputElement>(null);
  const lastNameRef = useRef<HTMLInputElement>(null);
  const address1Ref = useRef<HTMLInputElement>(null);
  const address2Ref = useRef<HTMLInputElement>(null);
  const cityRef = useRef<HTMLInputElement>(null);
  const stateRef = useRef<HTMLSelectElement>(null);
  const zipRef = useRef<HTMLInputElement>(null);
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const componentObjRef = useRef<ComponentVarProps>(componentVarObject);

  const [creditCardNumberBorderClass, setCreditCardNumberBorderClass] = useState("");
  const [securityCodeBorderClass, setSecurityCodeBorderClass] = useState("");
  const [firstNameBorderClass, setFirstNameBorderClass] = useState("");
  const [lastNameBorderClass, setLastNameBorderClass] = useState("");
  const [address1BorderClass, setAddress1BorderClass] = useState("");
  const [cityBorderClass, setCityBorderClass] = useState("");
  const [stateBorderClass, setStateBorderClass] = useState("");
  const [zipBorderClass, setZipBorderClass] = useState("");
  const [expirationMonthBorderClass, setExpirationMonthBorderClass] = useState("");
  const [expirationYearBorderClass, setExpirationYearBorderClass] = useState("");

  const [cardLabelUpClass, setCardLabelUpClass] = useState(floatingLabelClassConst);
  const [cvvLabelUpClass, setCvvLabelUpClass] = useState(floatingLabelClassConst);
  const [expMonthLabelUpClass, setExpMonthLabelUpClass] = useState(floatingLabelClassConst);
  const [expYearLabelUpClass, setExpYearLabelUpClass] = useState(floatingLabelClassConst);
  const [firstNameLabelUpClass, setFirstNameLabelUpClass] = useState(floatingLabelClassConst);
  const [lastNameLabelUpClass, setLastNameLabelUpClass] = useState(floatingLabelClassConst);
  const [addr1LabelUpClass, setAddr1LabelUpClass] = useState(floatingLabelClassConst);
  const [addr2LabelUpClass, setAddr2LabelUpClass] = useState(floatingLabelClassConst);
  const [cityLabelUpClass, setCityLabelUpClass] = useState(floatingLabelClassConst);
  const [stateLabelUpClass, setStateLabelUpClass] = useState(floatingLabelClassConst);
  const [zipLabelUpClass, setZipLabelUpClass] = useState(floatingLabelClassConst);

  const [submitButtonEnable, setSubmitButtonEnable] = useState(false);
  const [submitVisible, setSubmitVisible] = useState(true);

  const creditcardValidRef = useRef<boolean>(false);
  const creditcardRedBorderRef = useRef<boolean>(false);
  const cardDataEmptyRef = useRef<boolean>(true);
  const cvvValidRef = useRef<boolean>(false);
  const cvvDataEmptyRef = useRef<boolean>(true);
  const cvvRedBorderRef = useRef<boolean>(false);
  const submitCountRef = useRef(0);
  const logErrorRef = useRef<string>("MYCANON IFRAME");

  const [decryptParams, setDecryptParams] = useState([] as decryptParamType);
  const [proxyUrl, setProxyUrl] = useState("");

  const { appKey } = useParams();

  function setCreditCardType(newValue: string) {
    switch (newValue) {
      case "VI":
      case "visa":
        componentObjRef.current.glblCreditCardType = "VI";
        break;
      case "MC":
      case "mastercard":
        componentObjRef.current.glblCreditCardType = "MC";
        break;
      case "AX":
      case "amex":
        componentObjRef.current.glblCreditCardType = "AX";
        break;
      case "DI":
      case "discover":
        componentObjRef.current.glblCreditCardType = "DI";
        break;
      default:
        componentObjRef.current.glblCreditCardType = "";
        break;
    }
  }

  const checkCreditCard = useCallback(() => {
    let errorMessage = "";
    let validationFailedCount = 0;

    creditcardRedBorderRef.current = false;
    cvvRedBorderRef.current = false;

    if (decryptParams && Object.keys(decryptParams).length > 0 && submitButtonEnable) {
      let setFocusSet = false;
      let userMonth = expirationYearRef.current?.value + "" + expirationMonthRef.current?.value;

      if (componentObjRef.current.cardMessage !== "" || !creditcardValidRef.current) {
        errorMessage = INVALIDCARDMESSAGE;
        validationFailedCount = 1;
        // setCreditCardNumberBorderClass(`${classesMyCanon.redBorder}`);
        setCreditCardNumberBorderClass(`${classesMyCanon.mycanon} ${classesMyCanon.Input__Input} ${classesMyCanon.red}`);
        creditcardRedBorderRef.current = true;
        if (creditCardMicroNumber !== undefined && creditCardMicroNumber !== null) {
          creditCardMicroNumber.focus();
          setFocusSet = true;
        }
      } else {
        setCreditCardNumberBorderClass("");
      }
      if (componentObjRef.current.cvvMessage !== "" || !cvvValidRef.current) {
        errorMessage += (errorMessage.length > 0 ? " " : "") + INVALIDCVVMESSAGE;
        validationFailedCount = validationFailedCount + 1;
        // setSecurityCodeBorderClass(`${classesMyCanon.redBorder}`);
        setSecurityCodeBorderClass(`${classesMyCanon.mycanon} ${classesMyCanon.Input__Input} ${classesMyCanon.red}`);
        cvvRedBorderRef.current = true;
        if (!setFocusSet) {
          if (cvvMicroSecurityCode !== undefined && cvvMicroSecurityCode !== null) {
            cvvMicroSecurityCode.focus();
          }
        }
        setFocusSet = true;
      } else {
        setSecurityCodeBorderClass("");
      }

      if (userMonth < currentMonthYear) {
        errorMessage += (errorMessage.length > 0 ? " " : "") + INVALIDDATEMESSAGE;
        validationFailedCount = validationFailedCount + 1;
        if (!setFocusSet) expirationMonthRef.current?.focus();
        setFocusSet = true;
        setExpirationMonthBorderClass(`${classesMyCanon.mycanon} ${classesMyCanon.Input__Input} ${classesMyCanon.red}`);
        setExpirationYearBorderClass(`${classesMyCanon.mycanon} ${classesMyCanon.Input__Input} ${classesMyCanon.red}`);
        // expirationMonthRef.current!.style.border = "1px solid red";
      } else {
        // expirationMonthRef.current!.style.border = "1px solid #CC0000";
        setExpirationMonthBorderClass("");
        setExpirationYearBorderClass("");
      }
      if (componentObjRef.current.otherErrMsg !== "") {
        errorMessage += componentObjRef.current.otherErrMsg;
        validationFailedCount = validationFailedCount + 1;
        // setCreditCardNumberBorderClass(`${classesMyCanon.redBorder}`);
        setCreditCardNumberBorderClass(`${classesMyCanon.mycanon} ${classesMyCanon.Input__Input} ${classesMyCanon.red}`);
        creditcardRedBorderRef.current = true;
        if (!setFocusSet) {
          if (creditCardMicroNumber !== undefined && creditCardMicroNumber !== null) {
            creditCardMicroNumber.focus();
          }
        }
        setFocusSet = true;
      } else {
        if (componentObjRef.current.cardMessage === "" && !creditcardRedBorderRef.current) {
          setCreditCardNumberBorderClass("");
        }
      }

      if (firstNameRef.current?.value === "") {
        validationFailedCount = validationFailedCount + 1;
        errorMessage += (errorMessage.length > 0 ? " " : "") + EMPTYFIRSTNAMEMESSAGE;
        if (!setFocusSet) firstNameRef.current?.focus();
        setFocusSet = true;
        // setFirstNameBorderClass(`${classesMyCanon.redBorder}`);
        setFirstNameBorderClass(`${classesMyCanon.mycanon} ${classesMyCanon.Input__Input} ${classesMyCanon.red}`);
      } else {
        setFirstNameBorderClass("");
      }
      if (lastNameRef.current?.value === "") {
        validationFailedCount = validationFailedCount + 1;
        errorMessage += (errorMessage.length > 0 ? " " : "") + EMPTYLASTNAMEMESSAGE;
        if (!setFocusSet) lastNameRef.current?.focus();
        setFocusSet = true;
        // setLastNameBorderClass(`${classesMyCanon.redBorder}`);
        setLastNameBorderClass(`${classesMyCanon.mycanon} ${classesMyCanon.Input__Input} ${classesMyCanon.red}`);
      } else {
        setLastNameBorderClass("");
      }
      if (address1Ref.current?.value === "") {
        validationFailedCount = validationFailedCount + 1;
        errorMessage += (errorMessage.length > 0 ? " " : "") + EMPTYADDRESS1MESSAGE;
        if (!setFocusSet) address1Ref.current?.focus();
        setFocusSet = true;
        // setAddress1BorderClass(`${classesMyCanon.redBorder}`);
        setAddress1BorderClass(`${classesMyCanon.mycanon} ${classesMyCanon.Input__Input} ${classesMyCanon.red}`);
      } else {
        setAddress1BorderClass("");
      }
      if (cityRef.current?.value === "") {
        validationFailedCount = validationFailedCount + 1;
        errorMessage += (errorMessage.length > 0 ? " " : "") + EMPTYCITYMESSAGE;
        if (!setFocusSet) cityRef.current?.focus();
        setFocusSet = true;
        setCityBorderClass(`${classesMyCanon.mycanon} ${classesMyCanon.Input__Input} ${classesMyCanon.red}`);
      } else {
        setCityBorderClass("");
      }
      if (stateRef.current?.value.trim() === "") {
        validationFailedCount = validationFailedCount + 1;
        errorMessage += (errorMessage.length > 0 ? " " : "") + EMPTYSTATEMESSAGE;
        if (!setFocusSet) stateRef.current?.focus();
        setFocusSet = true;
        // setStateBorderClass(`${classesMyCanon.redBorder}`);
        setStateBorderClass(`${classesMyCanon.mycanon} ${classesMyCanon.Input__Input} ${classesMyCanon.red}`);
        // } else {
        //   let state = stateRef.current?.value.toUpperCase().trim();
        //   if (!isValidState(state as string)) {
        //     validationFailedCount = validationFailedCount + 1;
        //     errorMessage += (errorMessage.length > 0 ? " " : "") + VALIDSTATEMESSAGE;
        //     if (!setFocusSet) stateRef.current?.focus();
        //     setFocusSet = true;
        //     setStateBorderClass(`${classesMyCanon.redBorder}`);
        //   } else {
        //     setStateBorderClass("");
        //   }
      }
      if (zipRef.current?.value === "") {
        validationFailedCount = validationFailedCount + 1;
        errorMessage += (errorMessage.length > 0 ? " " : "") + EMPTYZIPMESSAGE;
        if (!setFocusSet) zipRef.current?.focus();
        setFocusSet = true;
        // setZipBorderClass(`${classesMyCanon.redBorder}`);
        setZipBorderClass(`${classesMyCanon.mycanon} ${classesMyCanon.Input__Input} ${classesMyCanon.red}`);
      } else {
        if (!isValidUSZip(zipRef.current?.value as string)) {
          validationFailedCount = validationFailedCount + 1;
          errorMessage += (errorMessage.length > 0 ? " " : "") + INVALIDZIPMESSAGE;
          if (!setFocusSet) zipRef.current?.focus();
          setFocusSet = true;
          // setZipBorderClass(`${classesMyCanon.redBorder}`);
          setZipBorderClass(`${classesMyCanon.mycanon} ${classesMyCanon.Input__Input} ${classesMyCanon.red}`);
        } else {
          setZipBorderClass("");
        }
      }
    } else {
      errorMessage = FORMINITIZING;
      validationFailedCount = 2;
    }

    if (errorMessage === "") {
      setErrmessage("");
      // isBackgroundRed = false;
      return true;
    } else {
      setErrmessage(validationFailedCount > 1 ? VALIDATIONFAILMESSAGE : errorMessage);
      componentObjRef.current.processingForm = false;
      // isBackgroundRed = true;
      return false;
    }
  }, [decryptParams, submitButtonEnable]);

  const toggleFields = useCallback((addProfile: string) => {
    const disabled = addProfile === "true" ? false : true;

    setCreditCardNumberDisabled(disabled);
    setExpirationMonthDisabled(disabled);
    setExpirationYearDisabled(disabled);
    setSecurityCodeDisabled(disabled);
    setFirstNameDisabled(disabled);
    setLastNameDisabled(disabled);
    setAddress1Disabled(disabled);
    setAddress2Disabled(disabled);
    setCityeDisabled(disabled);
    setStateDisabled(disabled);
    setZipDisabled(disabled);
    setErrmessageDisabled(true);
  }, []);

  const getLastFourCcAccountNum = (micoFormtoken: string) => {
    const tokenArray = micoFormtoken.split(".");
    let bufferObj = Buffer.from(tokenArray[1], "base64");

    //Encode the Buffer as a utf8 string
    let decodedString = bufferObj.toString("utf8");
    let jsonObject = JSON.parse(decodedString);
    let jti = jsonObject.jti;
    let lastFourDigits = jsonObject.content.paymentInformation.card.number.maskedValue.replaceAll("X", "");
    return [jti, lastFourDigits];
  };

  const invokeNewOrder = useCallback(
    async (micoFormtoken: string) => {
      if (componentObjRef.current.processingForm) {
        let expYearMonth = "";
        if (
          expirationMonthRef.current?.value &&
          expirationMonthRef.current?.value.length > 0 &&
          expirationYearRef.current?.value &&
          expirationYearRef.current?.value.length > 0
        ) {
          expYearMonth = expirationYearRef.current?.value + expirationMonthRef.current?.value;
        }

        const [jti, lastFourDigits] = getLastFourCcAccountNum(micoFormtoken);

        let proxyUrl = decryptParams["tcepps.proxyUrl"];
        let amount = decryptParams["amount"];
        let email = decryptParams["email"];
        if (!(email && email.length > 0)) {
          // email = DEFUSEREMAIL;
          email = decryptParams["customerEmail"];
        }
        let countryCd = decryptParams["country"];
        if (!(countryCd && countryCd.length > 0)) {
          countryCd = "US";
        }
        let orbitalConnectionUsername = decryptParams["pno.orbitalConnectionUsername"];
        let orbitalConnectionPassword = decryptParams["pno.orbitalConnectionPassword"];
        let switchSoloIssueNum = decryptParams["pno.switchSoloIssueNum"];
        let switchSoloCardStartDate = decryptParams["pno.switchSoloCardStartDate"];
        let authenticationECIInd = decryptParams["pno.authenticationECIInd"];
        if (!orbitalConnectionUsername) {
          orbitalConnectionUsername = decryptParams["orbitalConnectionUsername"];
        }
        if (!orbitalConnectionPassword) {
          orbitalConnectionPassword = decryptParams["orbitalConnectionPassword"];
        }
        if (!switchSoloIssueNum) {
          switchSoloIssueNum = decryptParams["switchSoloIssueNum"];
        }
        if (!switchSoloCardStartDate) {
          switchSoloCardStartDate = decryptParams["switchSoloCardStartDate"];
        }
        if (!authenticationECIInd) {
          authenticationECIInd = decryptParams["authenticationECIInd"];
        }

        if (developmentEnv) {
          let address = address1Ref.current?.value;
          if (address2Ref.current?.value) {
            address = address + " " + address2Ref.current?.value;
          }
          if (address) {
            if (address.toLowerCase().indexOf("testdonothonorfailure") >= 0) {
              amount = "40100";
            }
            if (address.toLowerCase().indexOf("testaddressfailure") >= 0) {
              amount = "283600";
            }
            if (address.toLowerCase().indexOf("testcvvfailure") >= 0) {
              amount = "700500";
              // cvv = "111";
            }
            if (address.toLowerCase().indexOf("testapifailure") >= 0) {
              email = "abcd@";
            }
            if (address.toLowerCase().indexOf("testinsufficientfund") >= 0) {
              amount = "250900"; //2509 2521
            }
            if (address.toLowerCase().indexOf("testinsufficientfund2") >= 0) {
              amount = "252100"; //2509 2521
            }
          }
        }
        let customerName = firstNameRef.current?.value + " " + lastNameRef.current?.value;
        customerName = customerName.replaceAll("  ", " ");
        const mycanonObj = {
          "pno.ccAccountNum": "TT=" + jti,
          "pno.ccExp": expYearMonth,
          "pno.ccCardVerifyNum": "",
          "pno.avsName": customerName,
          "pno.avsAddress1": address1Ref.current?.value,
          "pno.avsAddress2": address2Ref.current?.value,
          "pno.avsCity": cityRef.current?.value,
          "pno.avsState": stateRef.current?.value,
          "pno.avsZip": zipRef.current?.value,
          "pno.amount": amount,
          "pno.orderID": decryptParams["orderNumber"],
          "tcepps.cardBrand": componentObjRef.current.glblCreditCardType,
          "tcepps.lastFourDigits": lastFourDigits,
          "pno.avsCountryCode": countryCd.toUpperCase().trim(),
          "pno.customerEmail": email ? email.trim() : "",
          "pno.orbitalConnectionUsername": orbitalConnectionUsername ? orbitalConnectionUsername : "",
          "pno.orbitalConnectionPassword": orbitalConnectionPassword ? orbitalConnectionPassword : "",
          "pno.switchSoloIssueNum": switchSoloIssueNum ? switchSoloIssueNum : "",
          "pno.switchSoloCardStartDate": switchSoloCardStartDate ? switchSoloCardStartDate : "",
          "pno.authenticationECIInd": authenticationECIInd ? authenticationECIInd : "",
          "tcepps.proxyUrl": proxyUrl,
          "tcepps.serviceOperation": "newOrder",
          "tcepps.app": tceppsState.app.toUpperCase().trim(),
          "tcepps.token": tceppsState.token,
          "tcepps.sign": tceppsState.sign,
        };
        toggleFields("false");
        PaymentService.submitMycanon(mycanonObj)
          .then((resp) => {
            window.open(resp.data, "_self");
          })
          .catch((err: Error | AxiosError) => {
            if (axios.isAxiosError(err)) {
              // Access to config, request, and response
              setErrmessage(INVALIDAXIOSMESSAGE + "call to submitMycanon failed. " + err.message + ". Please check console..");
            } else {
              // Just a stock error
              setErrmessage("Error in submitMycanon. " + err.message + ". Please check console..");
            }
            // isBackgroundRed = true;
            componentObjRef.current.processingForm = false;
            LogService.logMessage(
              logErrorRef.current,
              LOGERRORLEVEL,
              "Call to submitMycanon failed:" + (err ? JSON.stringify(err.message) : "null")
            );
          });
      }
    },
    [toggleFields, tceppsState.app, tceppsState.token, tceppsState.sign, decryptParams]
  );

  const createCybersourceToken = useCallback(async () => {
    if (isCybersourceTimedout(toDay)) {
      (document.getElementById("errmessage") as HTMLInputElement).value = "Processing....";
      setErrmessage("Processing....");
      const { token, jti, lastFourDigits } = getTimedoutDummyToken();
      dispatch(tokenCreatedAction({ token, jti, lastFourDigits }));
    } else {
      componentObjRef.current.cardMessage = "";
      componentObjRef.current.cvvMessage = "";

      const options = {
        expirationMonth: expirationMonthRef.current?.value,
        expirationYear: expirationYearRef.current?.value,
      };

      // async function createMicroformToken(options: any) {
      await paymentInfo.microform.createToken(options, (err: Error, token: any) => {
        if (err) {
          // console.log(err);
          componentObjRef.current.otherErrMsg = "";
          componentObjRef.current.cardMessage = "";
          componentObjRef.current.cvvMessage = "";
          let foundCardMessage = true;
          // handle error
          // @ts-ignore
          switch (err.reason) {
            case "CREATE_TOKEN_NO_FIELDS_LOADED":
              componentObjRef.current.otherErrMsg = "Token creation error, no fields have been loaded." + err.message;
              break;
            case "CREATE_TOKEN_TIMEOUT":
              componentObjRef.current.otherErrMsg = "createToken call was unable to proceed." + err.message;
              break;
            case "CREATE_TOKEN_XHR_ERROR":
              componentObjRef.current.otherErrMsg = "Network error when attempting to create a token." + err.message;
              break;
            case "CREATE_TOKEN_NO_FIELDS":
              componentObjRef.current.otherErrMsg = "Data fields are unavailable for collection." + err.message;
              break;
            case "CREATE_TOKEN_VALIDATION_PARAMS":
              componentObjRef.current.otherErrMsg = "Issue with parameters supplied to createToken." + err.message;
              break;
            case "CREATE_TOKEN_VALIDATION_FIELDS":
              // @ts-ignore
              if (err.details && err.details.length > 0) {
                // @ts-ignore
                if (err.details.length > 1) {
                  componentObjRef.current.cardMessage = INVALIDCARDMESSAGE;
                  componentObjRef.current.cvvMessage = INVALIDCVVMESSAGE;
                  // @ts-ignore
                } else if (err.details[0].location === "number") {
                  componentObjRef.current.cardMessage = INVALIDCARDMESSAGE;
                  // @ts-ignore
                } else if (err.details[0].location === "securityCode") {
                  componentObjRef.current.cvvMessage = INVALIDCVVMESSAGE;
                  foundCardMessage = false;
                } else {
                  componentObjRef.current.otherErrMsg = err.message;
                }
              } else {
                componentObjRef.current.otherErrMsg = err.message;
              }
              break;
            case "CREATE_TOKEN_VALIDATION_SERVERSIDE":
              componentObjRef.current.otherErrMsg = "Server-side validation rejects the createToken request." + err.message;
              break;
            case "CREATE_TOKEN_UNABLE_TO_START":
              componentObjRef.current.otherErrMsg = "Loaded field was able to handle the createToken request." + err.message;
              break;
            default:
              componentObjRef.current.otherErrMsg = "Unknown error:" + err.message;
              break;
          }
          if (!foundCardMessage) componentObjRef.current.cardMessage = "";
          checkCreditCard();
          componentObjRef.current.processingForm = false;
        } else {
          // if cybersource token has expired (15 min)
          (document.getElementById("errmessage") as HTMLInputElement).value = "Processing....";
          setErrmessage("Processing....");
          const { jti, lastFourDigits } = parseToken(token);
          dispatch(tokenCreatedAction({ token, jti, lastFourDigits }));
          //dispatch(tokenCreatedAction(token));
        }
      });
      // createToken(options);
    }
  }, [paymentInfo.microform, dispatch, checkCreditCard]);

  // useEffect when new token is generated submit form
  useEffect(() => {
    // console.log("useEffect submit form...2");
    if (componentObjRef.current.processingForm && paymentInfo.token) {
      if (checkCreditCard()) {
        setErrmessage("Processing....");
        (document.getElementById("errmessage") as HTMLInputElement).value = "Processing....";

        invokeNewOrder(paymentInfo.token);
      } else {
        componentObjRef.current.processingForm = false;
      }
    }
  }, [paymentInfo.token, checkCreditCard, invokeNewOrder]);

  //useEffect when input parameters are decrypted.
  useEffect(() => {
    if (decryptParams && Object.keys(decryptParams).length > 0 && submitButtonEnable) {
      setSubmitVisible(true);
    }
  }, [decryptParams, submitButtonEnable]);

  // When Submit button is clicked
  const clickHandler = (event: any) => {
    event.preventDefault();
    submitCountRef.current = submitCountRef.current + 1;
    if (submitVisible && !componentObjRef.current.processingForm && checkCreditCard()) {
      componentObjRef.current.processingForm = true;
      createCybersourceToken();
    }
  };

  const windowMessageHandler = useCallback((event: MessageEvent) => {
    // console.log(event.data);
    // console.log(event.source);
    if (
      event.data &&
      (event.data as any).data !== undefined &&
      (event.data as any).data &&
      ((event.data as any).data as any).focusShift !== undefined &&
      ((event.data as any).data as any).focusShift &&
      (event.data as any).event !== undefined &&
      (event.data as any).event === "blur" &&
      event.currentTarget &&
      (event.currentTarget as any).name !== undefined &&
      (event.currentTarget as any).name &&
      (event.currentTarget as any).name === "creditCardFormIFrame"
    ) {
      if (((event.data as any).data as any).focusShift === "next") {
        if (componentObjRef.current.microFromFocusField === "creditCardNumber") {
          componentObjRef.current.microFromFocusField = "";
          if (cvvMicroSecurityCode !== undefined && cvvMicroSecurityCode !== null) {
            cvvMicroSecurityCode.focus();
          }
        } else {
          componentObjRef.current.microFromFocusField = "";
          expirationMonthRef.current?.focus();
        }
      } else if (((event.data as any).data as any).focusShift === "previous") {
        if (componentObjRef.current.microFromFocusField === "securityCode") {
          componentObjRef.current.microFromFocusField = "";
          if (creditCardMicroNumber !== undefined && creditCardMicroNumber !== null) {
            creditCardMicroNumber.focus();
          }
        } else {
          componentObjRef.current.microFromFocusField = "";
          submitButtonRef.current?.focus();
        }
      }
      // } else if (
      //   event.data &&
      //   event.data.type !== undefined &&
      //   event.data.type &&
      //   event.data.type === "INIT_INSTANCE" &&
      //   event.source &&
      //   (event.source as any).name !== undefined &&
      //   (event.source as any).name &&
      //   (event.source as any).name === "creditCardFormIFrame"
      // ) {
      //   if (creditCardMicroNumber !== undefined && creditCardMicroNumber !== null) {
      //     creditCardMicroNumber.focus();
      //     setSubmitButtonEnable(true);
      //   }
      // } else if (
      //   (isIE || isSafari || isFirefox || isEdge) &&
      //   event.data &&
      //   event.data.event !== undefined &&
      //   event.data.event &&
      //   event.data.event === "load"
      // ) {
      //   if (creditCardMicroNumber !== undefined && creditCardMicroNumber !== null) {
      //     creditCardMicroNumber.focus();
      //     setSubmitButtonEnable(true);
      //   }
    }
  }, []);

  // useEffect for windows message
  useEffect(() => {
    // console.log("useEffect addEventListener...3");
    if (paymentInfo.isSuccessful) {
      window.addEventListener("message", windowMessageHandler, false);
    }

    return () => {
      window.removeEventListener("message", windowMessageHandler);
    };
  }, [windowMessageHandler, paymentInfo.isSuccessful]);

  // decrypt the input parameters
  const decryptInputParameterAsync = async (app: string, encryptedRequestKey: string, encryptedRequest: string) => {
    let decryptParamsResponse: any;

    try {
      const response = await PaymentService.getDecryptRequest(app, encryptedRequestKey, encryptedRequest);
      decryptParamsResponse = response.data;
    } catch (err: any) {
      // console.log(err);
      if (axios.isAxiosError(err)) {
        // Access to config, request, and response
        setErrmessage(INVALIDAXIOSMESSAGE + "call to getDecryptRequest failed. " + err.message + ". Please check console..");
      } else {
        // Just a stock error
        setErrmessage("Error in getDecryptRequest. " + err.message + ". Please check console..");
      }
      LogService.logMessage(logErrorRef.current, LOGERRORLEVEL, "PaymentService.getDecryptRequest call failed. " + err.message);
      // isBackgroundRed = true;
    }

    if (decryptParamsResponse && Object.keys(decryptParamsResponse).length > 0) {
      // check for proxy
      let iFrameUrl = document.referrer;
      if (iFrameUrl === undefined || iFrameUrl.trim().length === 0) {
        setProxyUrl(decryptParamsResponse["tcepps.proxyUrl"]);
      }

      setExpMonthLabelUpClass(moveUpClassConst);
      moveUpPlaceholder("floatingLabelExpMonth", EXPMONTHPLACEHOLDER);
      setExpYearLabelUpClass(moveUpClassConst);
      moveUpPlaceholder("floatingLabelExpYear", EXPYEARPLACEHOLDER);

      // check if its called from testing app
      if (isTestingApp(decryptParamsResponse["app.TESTINGAPP"])) {
        developmentEnv = true;
        setExpirationMonth(TceppsTestData.ToBeAppTestData.MyCanon["ppa.test.expirationMonth"]);
        setExpirationYear(TceppsTestData.ToBeAppTestData.MyCanon["ppa.test.expirationYear"]);
        setFirstName(TceppsTestData.ToBeAppTestData.MyCanon["ppa.test.firstName"].toUpperCase());
        setLastName(TceppsTestData.ToBeAppTestData.MyCanon["ppa.test.lastName"].toUpperCase());
        setAddress1(TceppsTestData.ToBeAppTestData.MyCanon["ppa.test.address1"].toUpperCase());
        setAddress2(TceppsTestData.ToBeAppTestData.MyCanon["ppa.test.address2"].toUpperCase());
        setCity(TceppsTestData.ToBeAppTestData.MyCanon["ppa.test.city"].toUpperCase());
        setState(TceppsTestData.ToBeAppTestData.MyCanon["ppa.test.state"].toUpperCase());
        setZip(TceppsTestData.ToBeAppTestData.MyCanon["ppa.test.zip"].toUpperCase());
      }

      setDecryptParams(decryptParamsResponse);
      setFirstName(decryptParamsResponse.firstName ? decryptParamsResponse.firstName.toUpperCase().trim() : "");
      setLastName(decryptParamsResponse.lastName ? decryptParamsResponse.lastName.toUpperCase().trim() : "");
      setAddress1(decryptParamsResponse.address1 ? decryptParamsResponse.address1.toUpperCase().trim() : "");
      setAddress2(decryptParamsResponse.address2 ? decryptParamsResponse.address2.toUpperCase().trim() : "");
      setCity(decryptParamsResponse.city ? decryptParamsResponse.city.toUpperCase().trim() : "");
      setState(decryptParamsResponse.state ? decryptParamsResponse.state.toUpperCase().trim() : "");
      setZip(decryptParamsResponse.zip ? decryptParamsResponse.zip.toUpperCase().trim() : "");

      if (decryptParamsResponse.firstName) {
        setFirstNameLabelUpClass(moveUpClassConst);
        moveUpPlaceholder("floatingLabelFirstName", FIRSTNAMEPLACEHOLDER);
      }
      if (decryptParamsResponse.lastName) {
        setLastNameLabelUpClass(moveUpClassConst);
        moveUpPlaceholder("floatingLabelLastName", LASTNAMEPLACEHOLDER);
      }
      if (decryptParamsResponse.address1) {
        setAddr1LabelUpClass(moveUpClassConst);
        moveUpPlaceholder("floatingLabelAddr1", ADDR1PLACEHOLDER);
      }
      if (decryptParamsResponse.address2) {
        setAddr2LabelUpClass(moveUpClassConst);
        moveUpPlaceholder("floatingLabelAddr2", ADDR2PLACEHOLDER);
      }
      if (decryptParamsResponse.city) {
        setCityLabelUpClass(moveUpClassConst);
        moveUpPlaceholder("floatingLabelCity", CITYPLACEHOLDER);
      }
      if (decryptParamsResponse.state) {
        setStateLabelUpClass(moveUpClassConst);
        moveUpPlaceholder("floatingLabelState", STATEPLACEHOLDER);
      }
      if (decryptParamsResponse.zip) {
        setZipLabelUpClass(moveUpClassConst);
        moveUpPlaceholder("floatingLabelZip", ZIPCODEPLACEHOLDER);
      }
      logErrorRef.current = "MYCANON firstName#" + decryptParamsResponse["firstName"];
      return true;
    } else {
      setErrmessage("Customer details not available. Please close and retry.");
      LogService.logMessage(logErrorRef.current, LOGERRORLEVEL, "Customer details not available. Please close and retry.");
      return;
    }
  };

  // useEffect if screen/page input parameters change. Dispatch state change
  useEffect(() => {
    // console.log("useEffect initialload...4");

    const appKey0 = Buffer.from(appKey ? appKey : "", "base64");
    const appKey1 = appKey0.toString("utf8");
    const mydata = JSON.parse(appKey1);

    let iFrameUrl = document.referrer;
    if (iFrameUrl && iFrameUrl.trim().length > 0) {
      setProxyUrl(iFrameUrl);
    }

    let requestParameter = {
      app: "",
      token: "",
      sign: "",
      serviceOperation: "",
      encryptedRequest: "",
      encryptedRequestKey: "",
      enableNewCrCardFlag: "",
      addProfile: "",
      cpgmuiApiUrl: "",
      cpgmuiEnv: "",
    };

    const getPortNumber = (inputUrl: string) => {
      const windowsUrl = window.location.href;
      const searchParams = windowsUrl.indexOf("//") + 2;
      const firstSlashIndex = windowsUrl.indexOf("/", searchParams);
      const colonIndex = windowsUrl.indexOf(":", searchParams);
      if (firstSlashIndex > 0 && colonIndex > 0) {
        return windowsUrl.substring(colonIndex + 1, firstSlashIndex);
      } else {
        return "";
      }
    };

    //find port if it exits
    const urlPortNumber = getPortNumber(window.location.href);

    Object.keys(mydata).forEach((key) => {
      switch (key) {
        case "tcepps.app":
          requestParameter.app = mydata[key];
          break;
        case "tcepps.token":
          requestParameter.token = mydata[key];
          break;
        case "tcepps.sign":
          requestParameter.sign = mydata[key];
          break;
        case "tcepps.serviceOperation":
          requestParameter.serviceOperation = mydata[key];
          break;
        case "tcepps.encryptedRequest":
          requestParameter.encryptedRequest = mydata[key];
          break;
        case "tcepps.encryptedRequestKey":
          requestParameter.encryptedRequestKey = mydata[key];
          break;
        case "tcepps.enableNewCrCardFlag":
          requestParameter.enableNewCrCardFlag = mydata[key];
          break;
        case "tcepps.addProfile":
          requestParameter.addProfile = mydata[key];
          break;
        case "cpgmuiApiUrl":
          requestParameter.cpgmuiApiUrl = mydata[key] + "/TCEPPS/jsp/tceppsHtmlFormInterface.jsp?tceppsApp=";
          if (urlPortNumber !== "") {
            let backendPortNumber = getPortNumber(requestParameter.cpgmuiApiUrl);
            if (backendPortNumber !== urlPortNumber) {
              requestParameter.cpgmuiApiUrl = requestParameter.cpgmuiApiUrl.replace(backendPortNumber, urlPortNumber);
            }
          }
          break;
        case "cpgmuiEnv":
          requestParameter.cpgmuiEnv = mydata[key];
          if (requestParameter.cpgmuiEnv.toLowerCase() === "development" || requestParameter.cpgmuiEnv.toLowerCase() === "qa") {
            developmentEnv = true;
          }
          break;
      }
    });
    requestParameter.cpgmuiApiUrl += requestParameter.app;

    dispatch(
      tceppsActions.initializeTceppsAction({
        app: requestParameter.app,
        token: requestParameter.token,
        sign: requestParameter.sign,
        serviceOperation: requestParameter.serviceOperation,
        encryptedRequest: requestParameter.encryptedRequest,
        encryptedRequestKey: requestParameter.encryptedRequestKey,
        enableNewCrCardFlag: requestParameter.enableNewCrCardFlag,
        cpgmuiApiUrl: requestParameter.cpgmuiApiUrl,
        cpgmuiEnv: requestParameter.cpgmuiEnv,
      })
    );

    try {
      getYearsFromToday(11, document.getElementById("expirationYear") as HTMLSelectElement, false);
      getCnaUsStates(document.getElementById("state") as HTMLSelectElement, false);
    } catch (error) {}

    setExpirationMonth("01");
    setExpirationYear(currentYear);

    // if (developmentEnv && isDevUrl(window.location.href)) {
    //   setExpirationMonth(TceppsTestData.ToBeAppTestData.MyCanon["ppa.test.expirationMonth"]);
    //   setExpirationYear(TceppsTestData.ToBeAppTestData.MyCanon["ppa.test.expirationYear"]);
    //   setFirstName(TceppsTestData.ToBeAppTestData.MyCanon["ppa.test.firstName"].toUpperCase());
    //   setLastName(TceppsTestData.ToBeAppTestData.MyCanon["ppa.test.lastName"].toUpperCase());
    //   setAddress1(TceppsTestData.ToBeAppTestData.MyCanon["ppa.test.address1"].toUpperCase());
    //   setAddress2(TceppsTestData.ToBeAppTestData.MyCanon["ppa.test.address2"].toUpperCase());
    //   setCity(TceppsTestData.ToBeAppTestData.MyCanon["ppa.test.city"].toUpperCase());
    //   setState(TceppsTestData.ToBeAppTestData.MyCanon["ppa.test.state"].toUpperCase());
    //   setZip(TceppsTestData.ToBeAppTestData.MyCanon["ppa.test.zip"].toUpperCase());
    // }

    setCreditCardType("");
    setSelectedCreditCard("");

    decryptInputParameterAsync(
      requestParameter.app.toUpperCase().trim(),
      requestParameter.encryptedRequestKey,
      requestParameter.encryptedRequest
    );
  }, [appKey, dispatch]);

  const updateValidationMessages = (action: string, pdateString: string) => {
    // let errMsg = errmessageRef.current?.value;
    // if (errMsg === undefined) {
    //   errMsg = "";
    // }
    if (action === "add" || action === ADDMESSAGE) {
      // if (errMsg!.indexOf(pdateString) < 0) {
      // setErrmessage(errMsg?.length === 0 ? pdateString : errMsg + " " + pdateString);
      setErrmessage(pdateString);
      // }
    } else {
      // if (errMsg!.indexOf(pdateString) >= 0) {
      //   errMsg = errMsg?.replace(" " + pdateString, "");
      //   errMsg = errMsg?.replace(pdateString, "");
      //   setErrmessage(errMsg);
      // }
      setErrmessage("");
    }
  };

  // useEffect when form get loaded. setup microform
  useEffect(() => {
    // console.log("useEffect microform...5");
    const updateValidationMessages = (action: string, pdateString: string) => {
      // let errMsg = errmessageRef.current?.value;
      // if (errMsg === undefined) {
      //   errMsg = "";
      // }
      if (action === "add" || action === ADDMESSAGE) {
        setErrmessage(pdateString);
        // if (errMsg!.indexOf(pdateString) < 0) {
        //   setErrmessage(errMsg?.length === 0 ? pdateString : errMsg + " " + pdateString);
        // }
      } else {
        // if (errMsg!.indexOf(pdateString) >= 0) {
        //   errMsg = errMsg?.replace(" " + pdateString, "");
        //   errMsg = errMsg?.replace(pdateString, "");
        //   setErrmessage(errMsg);
        // }
        setErrmessage("");
      }
    };

    function selectGivenCardType(cardName: string, cardValid: boolean, cardEmpty: boolean) {
      let varCreditCardType = "";
      let detectedCard = false;
      if (cardName.length > 0) {
        switch (cardName) {
          case "VI":
          case "visa":
            varCreditCardType = "visa";
            detectedCard = true;
            break;
          case "MC":
          case "mastercard":
            varCreditCardType = "mastercard";
            detectedCard = true;
            break;
          case "AX":
          case "amex":
            varCreditCardType = "amex";
            detectedCard = true;
            break;
          case "DI":
          case "discover":
            varCreditCardType = "discover";
            detectedCard = true;
            break;
        }
      }

      if (detectedCard) {
        setCreditCardType(varCreditCardType);
        setSelectedCreditCard(varCreditCardType);
      } else {
        setCreditCardType("");
        setSelectedCreditCard("");
      }

      let errMessage = "Credit Card# needs to be entered.";
      if (!detectedCard) {
        errMessage = "Please enter a visa or a mastercard or amex or discover card#.";
      } else if (cardEmpty) {
        errMessage = "Credit Card# needs to be entered.";
      } else if (!cardValid) {
        errMessage = "Please enter a valid Credit card#.";
      } else if (cardValid) {
        errMessage = "";
      }
      creditcardValidRef.current = cardValid && detectedCard;
      componentObjRef.current.cardMessage = errMessage;
      updateValidationMessages(componentObjRef.current.cardMessage.length > 0 ? ADDMESSAGE : REMOVEMESSAGE, INVALIDCARDMESSAGE);
    }

    const loadCybersourceJS = (captureContext: any, clientLibrary: string, clientLibraryIntegrity: string, callback: (cc: any) => any) => {
      const script = document.createElement("script");
      script.type = "text/javascript";
      // script.src = config.cybersource.microformJs;
      script.src = clientLibrary;
      script.integrity = clientLibraryIntegrity;
      script.crossOrigin = "anonymous";
      document.body.appendChild(script);
      script.onload = () => {
        callback(captureContext);
      };
    };

    const initializeMicroform = async (captureContext: any) => {
      // @ts-ignore
      const flex = await new window.Flex(captureContext);
      const microform = await flex.microform({ styles: flexStyles });
      // creditCardMicroNumber = await microform.createField("number", { placeholder: "Card Number *" });
      creditCardMicroNumber = await microform.createField("number");
      // cvvMicroSecurityCode = await microform.createField("securityCode", { placeholder: "CVV *" });
      cvvMicroSecurityCode = await microform.createField("securityCode");
      await creditCardMicroNumber.load("#creditCardNumber");
      await cvvMicroSecurityCode.load("#securityCode");
      dispatch(microformInitializedAction(microform));

      if (creditCardMicroNumber !== undefined && creditCardMicroNumber !== null) {
        //add listener
        creditCardMicroNumber.on("change", function (data: any) {
          if (data.card && data.card.length > 0) {
            selectGivenCardType(data.card[0].name, data.valid, data.empty);
          } else {
            selectGivenCardType("", data.valid, data.empty);
          }
          cardDataEmptyRef.current = data.empty;
        });
        creditCardMicroNumber.on("load", function (data: any) {
          creditCardMicroNumber.focus();
          setSubmitButtonEnable(true);
        });
        creditCardMicroNumber.on("focus", function (data: any) {
          componentObjRef.current.microFromFocusField = "creditCardNumber";
          if (!creditcardRedBorderRef.current) {
            setCreditCardNumberBorderClass(`${classesMyCanon.mycanon} ${classesMyCanon.Input__Input} ${classesMyCanon.focusWithin}`);
            // (document.querySelector("#creditCardNumber") as HTMLDivElement).style.border = "1px solid #CC0000";
          }
          // move the floating label?
          setCardLabelUpClass(moveUpClassConst);
          moveUpPlaceholder("floatingLabelCardNum", CREDITCARDPLACEHOLDER);
        });
        creditCardMicroNumber.on("blur", function (data: any) {
          // (document.querySelector("#creditCardNumber") as HTMLDivElement).style.borderColor = "white";
          if (!creditcardRedBorderRef.current) {
            setCreditCardNumberBorderClass("");
          } else if (creditcardValidRef.current) {
            creditcardRedBorderRef.current = false;
            setCreditCardNumberBorderClass("");
          }
          // do we need to move the floating label?
          if (cardDataEmptyRef.current) {
            setCardLabelUpClass(floatingLabelClassConst);
          } else {
            setCardLabelUpClass(moveUpClassConst);
          }
          moveDownPlaceholder(cardDataEmptyRef.current ? "" : "dummyVal", "floatingLabelCardNum", CREDITCARDPLACEHOLDER);
        });
      }
      if (cvvMicroSecurityCode !== undefined && cvvMicroSecurityCode !== null) {
        cvvMicroSecurityCode.on("focus", function (data: any) {
          componentObjRef.current.microFromFocusField = "securityCode";
          // (document.querySelector("#securityCode") as HTMLDivElement).style.border = "1px solid #CC0000";
          if (!cvvRedBorderRef.current) {
            setSecurityCodeBorderClass(`${classesMyCanon.mycanon} ${classesMyCanon.Input__Input} ${classesMyCanon.focusWithin}`);
          }
          // move the floating label?
          setCvvLabelUpClass(moveUpClassConst);
          moveUpPlaceholder("floatingLabelCvv", CVVPLACEHOLDER);
        });
        cvvMicroSecurityCode.on("blur", function (data: any) {
          // (document.querySelector("#securityCode") as HTMLDivElement).style.borderColor = "white";
          if (!cvvRedBorderRef.current) {
            setSecurityCodeBorderClass("");
          } else if (cvvValidRef.current) {
            cvvRedBorderRef.current = false;
            setSecurityCodeBorderClass("");
          }
          // do we need to move the floating label?
          if (cvvDataEmptyRef.current) {
            setCvvLabelUpClass(floatingLabelClassConst);
          } else {
            setCvvLabelUpClass(moveUpClassConst);
          }
          moveDownPlaceholder(cvvDataEmptyRef.current ? "" : "dummyVal", "floatingLabelCvv", CVVPLACEHOLDER);
        });
        cvvMicroSecurityCode.on("change", function (data: any) {
          if (submitCountRef.current > 0 && (data.empty || !data.valid)) {
            updateValidationMessages(ADDMESSAGE, INVALIDCVVMESSAGE);
          } else if (submitCountRef.current > 0 && data.valid) {
            updateValidationMessages(REMOVEMESSAGE, INVALIDCVVMESSAGE);
          }
          cvvDataEmptyRef.current = data.empty;
          if (data.empty || !data.valid) {
            cvvValidRef.current = false;
          } else {
            cvvValidRef.current = true;
          }
        });
        // cvvMicroSecurityCode.on("inputSubmitRequest", function (data: any) {
        //   expirationMonthRef.current?.focus();
        // });
      }
    };

    if (proxyUrl && proxyUrl.length > 0) {
      // declare the data fetching function
      PaymentService.getCaptureContext(extractDomain(proxyUrl))
        .then((resp) => {
          // const captureContext = resp.data.data;
          const captureContext = resp.data.captureContext;
          const { clientLibrary, clientLibraryIntegrity } = extractClientLibrary(resp.data.json);
          dispatch(contextLoadedAction(captureContext));
          // @ts-ignore
          if (!window.Flex) {
            loadCybersourceJS(captureContext, clientLibrary, clientLibraryIntegrity, initializeMicroform);
          } else {
            initializeMicroform(captureContext);
          }
          return () => dispatch(unmountAction);
        })
        .catch((err: Error | AxiosError) => {
          // console.log(err);
          if (axios.isAxiosError(err)) {
            setErrmessage(INVALIDAXIOSMESSAGE + "call to initialize microForm failed. " + err.message + ". Please check console..");
          } else {
            setErrmessage("Initialize microForm failed. " + err.message + ". Please check console..");
          }
          LogService.logMessage(logErrorRef.current, LOGERRORLEVEL, "Initialize microForm failed. " + err.message);
          // isBackgroundRed = true;
        });
    }
  }, [dispatch, proxyUrl]);

  const onChangeHandler = (identifier: string, event: ChangeEvent & { target: HTMLInputElement | HTMLSelectElement }) => {
    let checkSelectedDate = false;
    let fieldChangeMessage = "";
    let messageAction = ADDMESSAGE;
    switch (identifier) {
      case "expirationMonth":
        setExpirationMonth(event.target.value);
        checkSelectedDate = true;
        break;
      case "expirationYear":
        setExpirationYear(event.target.value);
        checkSelectedDate = true;
        break;
      case "firstName":
        setFirstName(event.target.value);
        if (event.target.value.length === 0) {
          fieldChangeMessage = EMPTYFIRSTNAMEMESSAGE;
          if (submitCountRef.current > 0) {
            // setFirstNameBorderClass(`${classesMyCanon.redBorder}`);
            setFirstNameBorderClass(`${classesMyCanon.mycanon} ${classesMyCanon.Input__Input} ${classesMyCanon.red}`);
          }
        } else {
          fieldChangeMessage = EMPTYFIRSTNAMEMESSAGE;
          messageAction = REMOVEMESSAGE;
          if (submitCountRef.current > 0 && firstNameBorderClass) {
            setFirstNameBorderClass("");
          }
        }
        break;
      case "lastName":
        setLastName(event.target.value);
        if (event.target.value.length === 0) {
          fieldChangeMessage = EMPTYLASTNAMEMESSAGE;
          if (submitCountRef.current > 0) {
            // setLastNameBorderClass(`${classesMyCanon.redBorder}`);
            setLastNameBorderClass(`${classesMyCanon.mycanon} ${classesMyCanon.Input__Input} ${classesMyCanon.red}`);
          }
        } else {
          fieldChangeMessage = EMPTYLASTNAMEMESSAGE;
          messageAction = REMOVEMESSAGE;
          if (submitCountRef.current > 0 && lastNameBorderClass) {
            setLastNameBorderClass("");
          }
        }
        break;
      case "address1":
        setAddress1(event.target.value);
        if (event.target.value.length === 0) {
          fieldChangeMessage = EMPTYADDRESS1MESSAGE;
          if (submitCountRef.current > 0) {
            // setAddress1BorderClass(`${classesMyCanon.redBorder}`);
            setAddress1BorderClass(`${classesMyCanon.mycanon} ${classesMyCanon.Input__Input} ${classesMyCanon.red}`);
          }
        } else {
          fieldChangeMessage = EMPTYADDRESS1MESSAGE;
          messageAction = REMOVEMESSAGE;
          if (submitCountRef.current > 0 && address1BorderClass) {
            setAddress1BorderClass("");
          }
        }
        break;
      case "address2":
        setAddress2(event.target.value);
        break;
      case "city":
        setCity(event.target.value);
        if (event.target.value.length === 0) {
          fieldChangeMessage = EMPTYCITYMESSAGE;
          if (submitCountRef.current > 0) {
            setCityBorderClass(`${classesMyCanon.mycanon} ${classesMyCanon.Input__Input} ${classesMyCanon.red}`);
          }
        } else {
          fieldChangeMessage = EMPTYCITYMESSAGE;
          messageAction = REMOVEMESSAGE;
          if (submitCountRef.current > 0) {
            setCityBorderClass("");
          }
        }
        break;
      case "state":
        setState(event.target.value);
        if (event.target.value.trim().length === 0) {
          fieldChangeMessage = EMPTYSTATEMESSAGE;

          if (submitCountRef.current > 0) {
            setStateBorderClass(`${classesMyCanon.mycanon} ${classesMyCanon.Input__Input} ${classesMyCanon.red}`);
          }
        } else {
          fieldChangeMessage = EMPTYSTATEMESSAGE;
          messageAction = REMOVEMESSAGE;
          if (submitCountRef.current > 0 && stateBorderClass) {
            setStateBorderClass("");
          }
        }
        break;
      case "zip":
        setZip(event.target.value);
        if (event.target.value.length === 0) {
          fieldChangeMessage = EMPTYZIPMESSAGE;
          if (submitCountRef.current > 0) {
            setZipBorderClass(`${classesMyCanon.mycanon} ${classesMyCanon.Input__Input} ${classesMyCanon.red}`);
          }
        } else {
          fieldChangeMessage = EMPTYZIPMESSAGE;
          messageAction = REMOVEMESSAGE;
          if (submitCountRef.current > 0 && zipBorderClass) {
            setZipBorderClass("");
          }
        }
        break;
    }
    if (checkSelectedDate) {
      let userMonth = expirationYearRef.current?.value + "" + expirationMonthRef.current?.value;
      if (userMonth < currentMonthYear) {
        updateValidationMessages(ADDMESSAGE, INVALIDDATEMESSAGE);
        // expirationMonthRef.current!.style.border = "1px solid red";
        setExpirationMonthBorderClass(`${classesMyCanon.mycanon} ${classesMyCanon.Input__Input} ${classesMyCanon.red}`);
        setExpirationYearBorderClass(`${classesMyCanon.mycanon} ${classesMyCanon.Input__Input} ${classesMyCanon.red}`);
      } else {
        updateValidationMessages(REMOVEMESSAGE, INVALIDDATEMESSAGE);
        // expirationMonthRef.current!.style.border = "1px solid #CC0000";
        setExpirationMonthBorderClass("");
        setExpirationYearBorderClass("");
      }
    } else {
      updateValidationMessages(messageAction, fieldChangeMessage);
    }
  };

  const onBlurHandler = (
    identifier: string,
    floatingLabelIdentifier: string,
    placeholder: string,
    event: FocusEvent & { target: HTMLInputElement | HTMLSelectElement }
  ) => {
    let fieldChangeMessage = "";
    let messageAction = ADDMESSAGE;
    switch (identifier) {
      case "expirationMonth":
        setExpirationMonth(event.target.value);
        // event.target.style.border = "1px solid #CC0000";
        if (event.target.value.trim() === "") {
          setExpMonthLabelUpClass(floatingLabelClassConst);
        } else {
          setExpMonthLabelUpClass(moveUpClassConst);
        }
        break;
      case "expirationYear":
        setExpirationYear(event.target.value);
        // event.target.style.border = "1px solid #CC0000";
        if (event.target.value.trim() === "") {
          setExpYearLabelUpClass(floatingLabelClassConst);
        } else {
          setExpYearLabelUpClass(moveUpClassConst);
        }
        break;
      case "firstName":
        setFirstName(event.target.value.toUpperCase());
        if (event.target.value.trim() === "") {
          setFirstNameLabelUpClass(floatingLabelClassConst);
          fieldChangeMessage = EMPTYFIRSTNAMEMESSAGE;
        } else {
          messageAction = REMOVEMESSAGE;
          setFirstNameLabelUpClass(moveUpClassConst);
        }
        moveDownPlaceholder(event.target.value, floatingLabelIdentifier, placeholder);
        break;
      case "lastName":
        setLastName(event.target.value.toUpperCase());
        if (event.target.value.trim() === "") {
          fieldChangeMessage = EMPTYLASTNAMEMESSAGE;
          setLastNameLabelUpClass(floatingLabelClassConst);
        } else {
          messageAction = REMOVEMESSAGE;
          setLastNameLabelUpClass(moveUpClassConst);
        }
        moveDownPlaceholder(event.target.value, floatingLabelIdentifier, placeholder);
        break;
      case "address1":
        setAddress1(event.target.value.toUpperCase());
        if (event.target.value.trim() === "") {
          fieldChangeMessage = EMPTYADDRESS1MESSAGE;
          setAddr1LabelUpClass(floatingLabelClassConst);
        } else {
          messageAction = REMOVEMESSAGE;
          setAddr1LabelUpClass(moveUpClassConst);
        }
        moveDownPlaceholder(event.target.value, floatingLabelIdentifier, placeholder);
        break;
      case "address2":
        setAddress2(event.target.value.toUpperCase());
        if (event.target.value.trim() === "") {
          setAddr2LabelUpClass(floatingLabelClassConst);
        } else {
          setAddr2LabelUpClass(moveUpClassConst);
        }
        moveDownPlaceholder(event.target.value, floatingLabelIdentifier, placeholder);
        break;
      case "city":
        setCity(event.target.value.toUpperCase());
        if (event.target.value.trim() === "") {
          fieldChangeMessage = EMPTYCITYMESSAGE;
          setCityLabelUpClass(floatingLabelClassConst);
        } else {
          messageAction = REMOVEMESSAGE;
          setCityLabelUpClass(moveUpClassConst);
        }
        moveDownPlaceholder(event.target.value, floatingLabelIdentifier, placeholder);
        break;
      case "state":
        setState(event.target.value);
        if (event.target.value.trim() === "") {
          fieldChangeMessage = EMPTYSTATEMESSAGE;
          setStateLabelUpClass(floatingLabelClassConst);
        } else {
          messageAction = REMOVEMESSAGE;
          setStateLabelUpClass(moveUpClassConst);
        }
        moveDownPlaceholder(event.target.value, floatingLabelIdentifier, placeholder);
        break;
      case "zip":
        setZip(event.target.value.toUpperCase());
        if (event.target.value.trim() === "") {
          fieldChangeMessage = EMPTYZIPMESSAGE;
          setZipLabelUpClass(floatingLabelClassConst);
        } else {
          messageAction = REMOVEMESSAGE;
          setZipLabelUpClass(moveUpClassConst);
        }
        moveDownPlaceholder(event.target.value, floatingLabelIdentifier, placeholder);
        break;
    }
    updateValidationMessages(messageAction, fieldChangeMessage);
  };

  const onFocusHandler = (identifier: string, floatingLabelIdentifier: string, placeHolderValue: string) => {
    // event.target.style.border = "1px solid red";
    switch (identifier) {
      case "expirationMonth":
        // setExpirationMonth(event.target.value);
        setExpMonthLabelUpClass(moveUpClassConst);
        moveUpPlaceholder(floatingLabelIdentifier, placeHolderValue);
        break;
      case "expirationYear":
        // setExpirationYear(event.target.value);
        setExpYearLabelUpClass(moveUpClassConst);
        moveUpPlaceholder(floatingLabelIdentifier, placeHolderValue);
        break;
      case "firstName":
        // setFirstName(event.target.value);
        setFirstNameLabelUpClass(moveUpClassConst);
        moveUpPlaceholder(floatingLabelIdentifier, placeHolderValue);
        break;
      case "lastName":
        // setLastName(event.target.value);
        setLastNameLabelUpClass(moveUpClassConst);
        moveUpPlaceholder(floatingLabelIdentifier, placeHolderValue);
        break;
      case "address1":
        // setAddress1(event.target.value);
        setAddr1LabelUpClass(moveUpClassConst);
        moveUpPlaceholder(floatingLabelIdentifier, placeHolderValue);
        break;
      case "address2":
        // setAddress2(event.target.value);
        setAddr2LabelUpClass(moveUpClassConst);
        moveUpPlaceholder(floatingLabelIdentifier, placeHolderValue);
        break;
      case "city":
        // setCity(event.target.value);
        setCityLabelUpClass(moveUpClassConst);
        moveUpPlaceholder(floatingLabelIdentifier, placeHolderValue);
        break;
      case "state":
        // setState(event.target.value);
        setStateLabelUpClass(moveUpClassConst);
        moveUpPlaceholder(floatingLabelIdentifier, placeHolderValue);
        break;
      case "zip":
        // setZip(event.target.value);
        setZipLabelUpClass(moveUpClassConst);
        moveUpPlaceholder(floatingLabelIdentifier, placeHolderValue);
        break;
    }
  };

  const onKeyDownHandler = (identifier: string, event: KeyboardEvent<HTMLSelectElement | HTMLButtonElement>) => {
    if (event.key === "Tab") {
      if (identifier === "expirationMonth" && event.shiftKey) {
        if (cvvMicroSecurityCode !== undefined && cvvMicroSecurityCode !== null) {
          cvvMicroSecurityCode.focus();
        }
      } else if (identifier === "submit" && !event.shiftKey) {
        if (creditCardMicroNumber !== undefined && creditCardMicroNumber !== null) {
          creditCardMicroNumber.focus();
        }
      }
    }
  };

  return (
    <>
      <form id="tcepps" name="tcepps" action={tceppsState.cpgmuiApiUrl} target="_self">
        <input id="app" name="app" type="hidden" value={tceppsState.app} />
        <input id="token" name="token" type="hidden" value={tceppsState.token} />
        <input id="sign" name="sign" type="hidden" value={tceppsState.sign} />
        <input id="serviceOperation" name="serviceOperation" type="hidden" value="" />
        <input id="encryptedRequest" name="encryptedRequest" type="hidden" value={tceppsState.encryptedRequest} />
        <input id="encryptedRequestKey" name="encryptedRequestKey" type="hidden" value={tceppsState.encryptedRequestKey} />
        <input id="lastFourCcAccountNum" name="lastFourCcAccountNum" type="hidden" value="" />
        <input id="proxyUrl" name="proxyUrl" type="hidden" value="" />
      </form>
      <form id="pno" name="pno">
        <input id="ccAccountNum" name="ccAccountNum" type="hidden" value="" />
        <input id="ccExp" name="ccExp" type="hidden" value="" />
        <input id="ccCardVerifyNum" name="ccCardVerifyNum" type="hidden" value="" />
        <input id="avsName" name="avsName" type="hidden" value="" />
        <input id="avsAddress1" name="avsAddress1" type="hidden" value="" />
        <input id="avsAddress2" name="avsAddress2" type="hidden" value="" />
        <input id="avsCity" name="avsCity" type="hidden" value="" />
        <input id="avsState" name="avsState" type="hidden" value="" />
        <input id="avsZip" name="avsZip" type="hidden" value="" />
        <input id="avsCountryCode" name="avsCountryCode" type="hidden" value="" />
        <input id="amount" name="amount" type="hidden" value="" />
        <input id="orderID" name="orderID" type="hidden" value="" />
        <input id="cardBrand" name="cardBrand" type="hidden" value="" />
        <input id="customerEmail" name="customerEmail" type="hidden" value="" />
        <input id="customerPhone" name="customerPhone" type="hidden" value="" />
      </form>
      <div className={`${classesMyCanon.mycanon} ${classesMyCanon.top_level}`}>
        <div className={`${classesMyCanon.mycanon} ${classesMyCanon.custom_overlay}`}>
          <div className={`${classesMyCanon.mycanon} ${classesMyCanon.Form__Section}`}>
            <div className="">
              <form id="creditCard" className={`${classesMyCanon.mycanon} ${classesMyCanon.Form__Form}`}>
                <div className="">
                  <div id="info-field" className="">
                    <div style={margin20Style}>
                      <h6 className={`${classesMyCanon.mycanon} ${classesMyCanon.Label__main}`}>Card Information</h6>
                      <div className={`${classesMyCanon.mycanon} ${classesMyCanon.Label__group}`}></div>
                    </div>
                    <div id="cnumdiv">
                      <div className={`${classesMyCanon.mycanon} ${classesMyCanon.Row__Row} ${classesMyCanon.Row__padding_sm}`}>
                        <div
                          className={`${classesMyCanon.mycanon} ${classesMyCanon.Col__Col} ${classesMyCanon.Col__sm_12} ${classesMyCanon.Col__md_9} ${classesMyCanon.Col__padding_sm}`}
                        >
                          <div>
                            <div className={`${classesMyCanon.mycanon} ${classesMyCanon.Label__group}`}>
                              <div
                                className={`${classesMyCanon.mycanon} ${classesMyCanon.Input__Input} ${classesMyCanon.Search__SearchInput} ${creditCardNumberBorderClass}`}
                              >
                                <span className={`${classesMyCanon.mycanon} ${classesMyCanon.inputcontainer}`} style={{ width: "100%" }}>
                                  <span className={`${cardLabelUpClass}`} id="floatingLabelCardNum" aria-readonly style={{ width: "100%" }}>
                                    {CREDITCARDPLACEHOLDER}
                                  </span>
                                  <div
                                    id="creditCardNumber"
                                    style={creditCardStyle}
                                    tabIndex={1}
                                    ref={creditCardNumberRef}
                                    aria-disabled={creditCardNumberDisabled}
                                  ></div>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className={`${classesMyCanon.mycanon} ${classesMyCanon.Col__Col} ${classesMyCanon.Col__sm_12} ${classesMyCanon.Col__md_3} ${classesMyCanon.Col__padding_sm}`}
                        >
                          <div className="" id="a">
                            <div className={`${classesMyCanon.mycanon} ${classesMyCanon.Label__group}`}>
                              <div className={`${classesMyCanon.mycanon} ${classesMyCanon.Search__SearchContainer}`}>
                                <div
                                  className={`${classesMyCanon.mycanon} ${classesMyCanon.Input__Input} ${classesMyCanon.Search__SearchInput} ${securityCodeBorderClass}`}
                                >
                                  <span className={`${classesMyCanon.mycanon} ${classesMyCanon.inputcontainer}`} style={{ width: "100%" }}>
                                    <span className={`${cvvLabelUpClass}`} id="floatingLabelCvv" aria-readonly style={{ width: "100%" }}>
                                      {CVVPLACEHOLDER}
                                    </span>
                                    <div
                                      id="securityCode"
                                      style={creditCardStyle}
                                      tabIndex={2}
                                      ref={securityCodeRef}
                                      aria-disabled={securityCodeDisabled}
                                    ></div>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="">
                        <div
                          className={`${classesMyCanon.mycanon} ${classesMyCanon.Row__Row} ${classesMyCanon.Row__padding_sm} ${classesMyCanon.Row__Row_Left}`}
                        >
                          <div className={`${classesMyCanon.mycanon} ${classesMyCanon.CardButton__Group_sm}`}>
                            <div
                              className={`${classesMyCanon.mycanon} ${classesMyCanon.CardButton__Group_sm} ${classesMyCanon.CardButton__inner_sm}`}
                            >
                              <div
                                className={`${classesMyCanon.mycanon} ${classesMyCanon.Link__Link} ${classesMyCanon.CardButton__CardButton_sm}`}
                              >
                                <input
                                  type="radio"
                                  value="visa"
                                  name="cardtype"
                                  id="visa"
                                  tabIndex={-1}
                                  disabled={true}
                                  checked={selectedCreditCard === "visa" || selectedCreditCard === "VI"}
                                  onClick={() => {
                                    return false;
                                  }}
                                />
                                <label htmlFor="visa"></label>
                                <img src={require("./img/vi.png")} alt="Visa" />
                              </div>
                              <div
                                className={`${classesMyCanon.mycanon} ${classesMyCanon.Link__Link} ${classesMyCanon.CardButton__CardButton_sm}`}
                              >
                                <input
                                  type="radio"
                                  value="mastercard"
                                  name="cardtype"
                                  id="mastercard"
                                  tabIndex={-1}
                                  disabled={true}
                                  checked={selectedCreditCard === "mastercard" || selectedCreditCard === "MC"}
                                  onClick={() => {
                                    return false;
                                  }}
                                />
                                <label htmlFor="mastercard"></label>
                                <img src={require("./img/mc.png")} alt="Mastercard" />
                              </div>
                              <div
                                className={`${classesMyCanon.mycanon} ${classesMyCanon.Link__Link} ${classesMyCanon.CardButton__CardButton_sm}`}
                              >
                                <input
                                  type="radio"
                                  value="amex"
                                  name="cardtype"
                                  id="amex"
                                  tabIndex={-1}
                                  disabled={true}
                                  checked={selectedCreditCard === "amex" || selectedCreditCard === "AX"}
                                  onClick={() => {
                                    return false;
                                  }}
                                />
                                <label htmlFor="amex"></label>
                                <img src={require("./img/ae.png")} alt="American Express" />
                              </div>
                              <div
                                className={`${classesMyCanon.mycanon} ${classesMyCanon.Link__Link} ${classesMyCanon.CardButton__CardButton_sm}`}
                              >
                                <input
                                  type="radio"
                                  value="discover"
                                  name="cardtype"
                                  id="discover"
                                  tabIndex={-1}
                                  disabled={true}
                                  checked={selectedCreditCard === "discover" || selectedCreditCard === "DI"}
                                  onClick={() => {
                                    return false;
                                  }}
                                />
                                <label htmlFor="discover"></label>
                                <img src={require("./img/di.png")} alt="Discover" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className={`${classesMyCanon.mycanon} ${classesMyCanon.Row__Row} ${classesMyCanon.Row__padding_sm}`}>
                        <div
                          className={`${classesMyCanon.mycanon} ${classesMyCanon.Col__Col} ${classesMyCanon.Col__sm_12} ${classesMyCanon.Col__md_6} ${classesMyCanon.Col__padding_sm}`}
                        >
                          <div className="" id="b">
                            <div className={`${classesMyCanon.mycanon} ${classesMyCanon.Label__group}`}>
                              <div className={`${classesMyCanon.mycanon} ${classesMyCanon.Input__Input} ${expirationMonthBorderClass}`}>
                                <span className={`${classesMyCanon.mycanon} ${classesMyCanon.inputcontainer}`} style={{ width: "100%" }}>
                                  <span
                                    className={`${expMonthLabelUpClass}`}
                                    id="floatingLabelExpMonth"
                                    aria-readonly
                                    style={{ width: "80%" }}
                                  >
                                    {EXPMONTHPLACEHOLDER}
                                  </span>
                                  <select
                                    id="expirationMonth"
                                    name="expirationMonth"
                                    tabIndex={3}
                                    value={expirationMonth}
                                    disabled={expirationMonthDisabled}
                                    ref={expirationMonthRef}
                                    style={formElemStyle}
                                    // onChange={(event) => onChangeHandler("expirationMonth", event)}
                                    onKeyDown={(event) => onKeyDownHandler("expirationMonth", event)}
                                    // onBlur={(event) => onBlurHandler("expirationMonth", event)}
                                    // onFocus={(event) => onFocusHandler("expirationMonth", event)}
                                    // className={`${classesMyCanon.mycanon} ${classesMyCanon.Input__Input}`}
                                    onChange={(event) => onChangeHandler("expirationMonth", event)}
                                    onBlur={(event) =>
                                      onBlurHandler("expirationMonth", "floatingLabelExpMonth", EXPMONTHPLACEHOLDER, event)
                                    }
                                    // onInput={(event) => updateinputfield("expirationMonth")}
                                    onFocus={() => onFocusHandler("expirationMonth", "floatingLabelExpMonth", EXPMONTHPLACEHOLDER)}
                                  >
                                    <option value="01">01 - January</option>
                                    <option value="02">02 - February</option>
                                    <option value="03">03 - March</option>
                                    <option value="04">04 - April</option>
                                    <option value="05">05 - May</option>
                                    <option value="06">06 - June</option>
                                    <option value="07">07 - July</option>
                                    <option value="08">08 - August</option>
                                    <option value="09">09 - September</option>
                                    <option value="10">10 - October</option>
                                    <option value="11">11 - November</option>
                                    <option value="12">12 - December</option>
                                  </select>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className={`${classesMyCanon.mycanon} ${classesMyCanon.Col__Col} ${classesMyCanon.Col__sm_12} ${classesMyCanon.Col__md_6} ${classesMyCanon.Col__padding_sm}`}
                        >
                          <div className="" id="c">
                            <div className={`${classesMyCanon.mycanon} ${classesMyCanon.Label__group}`}>
                              <div className={`${classesMyCanon.mycanon} ${classesMyCanon.Input__Input} ${expirationYearBorderClass}`}>
                                <span className={`${classesMyCanon.mycanon} ${classesMyCanon.inputcontainer}`} style={{ width: "100%" }}>
                                  <span
                                    className={`${expYearLabelUpClass}`}
                                    id="floatingLabelExpYear"
                                    aria-readonly
                                    style={{ width: "80%" }}
                                  >
                                    {EXPYEARPLACEHOLDER}
                                  </span>
                                  <select
                                    id="expirationYear"
                                    name="expirationYear"
                                    tabIndex={4}
                                    value={expirationYear}
                                    disabled={expirationYearDisabled}
                                    ref={expirationYearRef}
                                    style={formElemStyle}
                                    // onChange={(event) => onChangeHandler("expirationYear", event)}
                                    // onBlur={(event) => onBlurHandler("expirationYear", event)}
                                    // onFocus={(event) => onFocusHandler("expirationYear", event)}
                                    // className={`${classesMyCanon.mycanon} ${classesMyCanon.Input__Input}`}
                                    onChange={(event) => onChangeHandler("expirationYear", event)}
                                    onBlur={(event) => onBlurHandler("expirationYear", "floatingLabelExpYear", EXPYEARPLACEHOLDER, event)}
                                    // onInput={(event) => updateinputfield("expirationYear")}
                                    onFocus={() => onFocusHandler("expirationYear", "floatingLabelExpYear", EXPYEARPLACEHOLDER)}
                                  ></select>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={margin20Style}>
                      <h6 className={`${classesMyCanon.mycanon} ${classesMyCanon.Label__main}`}>Billing Address</h6>
                      <div className={`${classesMyCanon.mycanon} ${classesMyCanon.Label__group}`}></div>
                    </div>
                    <div className="">
                      <div className={`${classesMyCanon.mycanon} ${classesMyCanon.Row__Row} ${classesMyCanon.Row__padding_sm}`}>
                        <div
                          className={`${classesMyCanon.mycanon} ${classesMyCanon.Col__Col} ${classesMyCanon.Col__sm_12} ${classesMyCanon.Col__md_6} ${classesMyCanon.Col__padding_sm}`}
                        >
                          <div className="">
                            <div className={`${classesMyCanon.mycanon} ${classesMyCanon.Label__group}`}>
                              <div className={`${classesMyCanon.mycanon} ${classesMyCanon.Input__Input} ${firstNameBorderClass}`}>
                                <span className={`${classesMyCanon.mycanon} ${classesMyCanon.inputcontainer}`} style={{ width: "100%" }}>
                                  <span
                                    className={`${firstNameLabelUpClass}`}
                                    id="floatingLabelFirstName"
                                    aria-readonly
                                    style={{ width: "80%" }}
                                  >
                                    {FIRSTNAMEPLACEHOLDER}
                                  </span>
                                  <input
                                    id="firstName"
                                    name="firstName"
                                    type="text"
                                    // placeholder="First Name *"
                                    size={60}
                                    maxLength={60}
                                    tabIndex={5}
                                    value={firstName}
                                    disabled={firstNameDisabled}
                                    ref={firstNameRef}
                                    style={formElemStyle}
                                    // onChange={(event) => onChangeHandler("firstName", event)}
                                    // onBlur={(event) => onBlurHandler("firstName", event)}
                                    // onFocus={(event) => onFocusHandler("firstName", event)}
                                    onChange={(event) => onChangeHandler("firstName", event)}
                                    onBlur={(event) => onBlurHandler("firstName", "floatingLabelFirstName", FIRSTNAMEPLACEHOLDER, event)}
                                    // onInput={(event) => updateinputfield("firstName")}
                                    onFocus={() => onFocusHandler("firstName", "floatingLabelFirstName", FIRSTNAMEPLACEHOLDER)}
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className={`${classesMyCanon.mycanon} ${classesMyCanon.Col__Col} ${classesMyCanon.Col__sm_12} ${classesMyCanon.Col__md_6} ${classesMyCanon.Col__padding_sm}`}
                        >
                          <div className="">
                            <div className={`${classesMyCanon.mycanon} ${classesMyCanon.Label__group}`}>
                              <div className={`${classesMyCanon.mycanon} ${classesMyCanon.Input__Input} ${lastNameBorderClass}`}>
                                <span className={`${classesMyCanon.mycanon} ${classesMyCanon.inputcontainer}`} style={{ width: "100%" }}>
                                  <span
                                    className={`${lastNameLabelUpClass}`}
                                    id="floatingLabelLastName"
                                    aria-readonly
                                    style={{ width: "80%" }}
                                  >
                                    {LASTNAMEPLACEHOLDER}
                                  </span>
                                  <input
                                    id="lastName"
                                    name="lastName"
                                    type="text"
                                    // placeholder="Last Name *"
                                    size={60}
                                    maxLength={60}
                                    tabIndex={6}
                                    value={lastName}
                                    disabled={lastNameDisabled}
                                    ref={lastNameRef}
                                    style={formElemStyle}
                                    // onChange={(event) => onChangeHandler("lastName", event)}
                                    // onBlur={(event) => onBlurHandler("lastName", event)}
                                    // onFocus={(event) => onFocusHandler("lastName", event)}
                                    onChange={(event) => onChangeHandler("lastName", event)}
                                    onBlur={(event) => onBlurHandler("lastName", "floatingLabelLastName", LASTNAMEPLACEHOLDER, event)}
                                    // onInput={(event) => updateinputfield("lastName")}
                                    onFocus={() => onFocusHandler("lastName", "floatingLabelLastName", LASTNAMEPLACEHOLDER)}
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={`${classesMyCanon.mycanon} ${classesMyCanon.Row__Row} ${classesMyCanon.Row__padding_sm}`}>
                        <div
                          className={`${classesMyCanon.mycanon} ${classesMyCanon.Col__Col} ${classesMyCanon.Col__sm_12} ${classesMyCanon.Col__md_8} ${classesMyCanon.Col__padding_sm}`}
                        >
                          <div className="">
                            <div className={`${classesMyCanon.mycanon} ${classesMyCanon.Label__group}`}>
                              <div className={`${classesMyCanon.mycanon} ${classesMyCanon.Search__SearchContainer}`}>
                                <div
                                  className={`${classesMyCanon.mycanon} ${classesMyCanon.Input__Input} ${classesMyCanon.Search__SearchInput} ${address1BorderClass}`}
                                >
                                  <span className={`${classesMyCanon.mycanon} ${classesMyCanon.inputcontainer}`} style={{ width: "100%" }}>
                                    <span className={`${addr1LabelUpClass}`} id="floatingLabelAddr1" aria-readonly style={{ width: "80%" }}>
                                      {ADDR1PLACEHOLDER}
                                    </span>
                                    <input
                                      id="address1"
                                      name="address1"
                                      type="text"
                                      // placeholder="Street Address *"
                                      size={50}
                                      maxLength={60}
                                      tabIndex={7}
                                      value={address1}
                                      disabled={address1Disabled}
                                      ref={address1Ref}
                                      style={formElemStyle}
                                      // onChange={(event) => onChangeHandler("address1", event)}
                                      // onBlur={(event) => onBlurHandler("address1", event)}
                                      // onFocus={(event) => onFocusHandler("address1", event)}
                                      onChange={(event) => onChangeHandler("address1", event)}
                                      onBlur={(event) => onBlurHandler("address1", "floatingLabelAddr1", ADDR1PLACEHOLDER, event)}
                                      // onInput={(event) => updateinputfield("address1")}
                                      onFocus={() => onFocusHandler("address1", "floatingLabelAddr1", ADDR1PLACEHOLDER)}
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className={`${classesMyCanon.mycanon} ${classesMyCanon.Col__Col} ${classesMyCanon.Col__sm_12} ${classesMyCanon.Col__md_4} ${classesMyCanon.Col__padding_sm}`}
                        >
                          <div className="">
                            <div className={`${classesMyCanon.mycanon} ${classesMyCanon.Label__group}`}>
                              <div className={`${classesMyCanon.mycanon} ${classesMyCanon.Input__Input}`}>
                                <span className={`${classesMyCanon.mycanon} ${classesMyCanon.inputcontainer}`} style={{ width: "100%" }}>
                                  <span className={`${addr2LabelUpClass}`} id="floatingLabelAddr2" aria-readonly style={{ width: "80%" }}>
                                    {ADDR2PLACEHOLDER}
                                  </span>
                                  <input
                                    id="address2"
                                    name="address2"
                                    type="text"
                                    // placeholder="Apt or Suite"
                                    size={50}
                                    maxLength={60}
                                    tabIndex={8}
                                    value={address2}
                                    disabled={address2Disabled}
                                    ref={address2Ref}
                                    style={formElemStyle}
                                    // onChange={(event) => onChangeHandler("address2", event)}
                                    // onBlur={(event) => onBlurHandler("address2", event)}
                                    // onFocus={(event) => onFocusHandler("address2", event)}
                                    onChange={(event) => onChangeHandler("address2", event)}
                                    onBlur={(event) => onBlurHandler("address2", "floatingLabelAddr2", ADDR2PLACEHOLDER, event)}
                                    // onInput={(event) => updateinputfield("address2")}
                                    onFocus={() => onFocusHandler("address2", "floatingLabelAddr2", ADDR2PLACEHOLDER)}
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={`${classesMyCanon.mycanon} ${classesMyCanon.Row__Row} ${classesMyCanon.Row__padding_sm}`}>
                        <div
                          className={`${classesMyCanon.mycanon} ${classesMyCanon.Col__Col} ${classesMyCanon.Col__sm_12} ${classesMyCanon.Col__md_6} ${classesMyCanon.Col__padding_sm}`}
                        >
                          <div className="">
                            <div className={`${classesMyCanon.mycanon} ${classesMyCanon.Label__group}`}>
                              <div className={`${classesMyCanon.mycanon} ${classesMyCanon.Input__Input} ${cityBorderClass}`}>
                                <span className={`${classesMyCanon.mycanon} ${classesMyCanon.inputcontainer}`} style={{ width: "100%" }}>
                                  <span className={`${cityLabelUpClass}`} id="floatingLabelCity" aria-readonly style={{ width: "80%" }}>
                                    {CITYPLACEHOLDER}
                                  </span>
                                  <input
                                    id="city"
                                    name="city"
                                    type="text"
                                    // placeholder="City *"
                                    size={25}
                                    maxLength={cityMaxLength}
                                    tabIndex={9}
                                    value={city}
                                    disabled={cityDisabled}
                                    ref={cityRef}
                                    style={formElemStyle}
                                    // onChange={(event) => onChangeHandler("city", event)}
                                    // onBlur={(event) => onBlurHandler("city", event)}
                                    // onFocus={(event) => onFocusHandler("city", event)}
                                    onChange={(event) => onChangeHandler("city", event)}
                                    onBlur={(event) => onBlurHandler("city", "floatingLabelCity", CITYPLACEHOLDER, event)}
                                    // onInput={(event) => updateinputfield("city")}
                                    onFocus={() => onFocusHandler("city", "floatingLabelCity", CITYPLACEHOLDER)}
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className={`${classesMyCanon.mycanon} ${classesMyCanon.Col__Col} ${classesMyCanon.Col__sm_12} ${classesMyCanon.Col__md_3} ${classesMyCanon.Col__padding_sm1}`}
                        >
                          <div className="">
                            <div className={`${classesMyCanon.mycanon} ${classesMyCanon.Label__group}`}>
                              <div className={`${classesMyCanon.mycanon} ${classesMyCanon.Input__Input} ${stateBorderClass}`}>
                                <span className={`${classesMyCanon.mycanon} ${classesMyCanon.inputcontainer}`} style={{ width: "100%" }}>
                                  <span className={`${stateLabelUpClass}`} id="floatingLabelState" aria-readonly style={{ width: "80%" }}>
                                    {STATEPLACEHOLDER}
                                  </span>
                                  <select
                                    id="state"
                                    name="state"
                                    tabIndex={10}
                                    value={state}
                                    disabled={stateDisabled}
                                    ref={stateRef}
                                    style={formElemStyle}
                                    // onChange={(event) => onChangeHandler("state", event)}
                                    // onBlur={(event) => onBlurHandler("state", event)}
                                    // onFocus={(event) => onFocusHandler("state", event)}
                                    onChange={(event) => onChangeHandler("state", event)}
                                    onBlur={(event) => onBlurHandler("state", "floatingLabelState", STATEPLACEHOLDER, event)}
                                    // onInput={(event) => updateinputfield("state")}
                                    onFocus={() => onFocusHandler("state", "floatingLabelState", STATEPLACEHOLDER)}
                                  ></select>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className={`${classesMyCanon.mycanon} ${classesMyCanon.Col__Col} ${classesMyCanon.Col__sm_12} ${classesMyCanon.Col__md_3} ${classesMyCanon.Col__padding_sm}`}
                        >
                          <div className="">
                            <div className={`${classesMyCanon.mycanon} ${classesMyCanon.Label__group}`}>
                              <div className={`${classesMyCanon.mycanon} ${classesMyCanon.Input__Input} ${zipBorderClass}`}>
                                <span className={`${classesMyCanon.mycanon} ${classesMyCanon.inputcontainer}`} style={{ width: "100%" }}>
                                  <span className={`${zipLabelUpClass}`} id="floatingLabelZip" aria-readonly style={{ width: "80%" }}>
                                    {ZIPCODEPLACEHOLDER}
                                  </span>
                                  <input
                                    id="zip"
                                    name="zip"
                                    type="text"
                                    // placeholder="Zip Code *"
                                    size={10}
                                    maxLength={12}
                                    tabIndex={11}
                                    value={zip}
                                    disabled={zipDisabled}
                                    ref={zipRef}
                                    style={formElemStyle}
                                    onChange={(event) => onChangeHandler("zip", event)}
                                    onBlur={(event) => onBlurHandler("zip", "floatingLabelZip", ZIPCODEPLACEHOLDER, event)}
                                    onFocus={() => onFocusHandler("zip", "floatingLabelZip", ZIPCODEPLACEHOLDER)}
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=""></div>
                  <div hidden={!submitVisible}>
                    <div className={`${classesMyCanon.mycanon} ${classesMyCanon.Form__Footer}`}>
                      <div
                        className={`${classesMyCanon.mycanon} ${classesMyCanon.Button__Button} ${classesMyCanon.Button__alt}`}
                        onClick={(event) => clickHandler(event)}
                        hidden={!submitVisible}
                      >
                        <button
                          className={`${classesMyCanon.mycanon} ${classesMyCanon.Button__text} ${classesMyCanon.body}`}
                          tabIndex={12}
                          onClick={(event) => clickHandler(event)}
                          disabled={!submitButtonEnable}
                          ref={submitButtonRef}
                          hidden={!submitVisible}
                        >
                          SUBMIT PAYMENT
                        </button>
                      </div>
                      <input
                        id="errmessage"
                        name="errmessage"
                        type="text"
                        size={95}
                        maxLength={95}
                        tabIndex={-1}
                        value={errmessage}
                        disabled={errmessageDisabled}
                        ref={errmessageRef}
                        className={`${classesMyCanon.mycanon} ${classesMyCanon.errmessage}`}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
