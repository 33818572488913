import React, { FocusEvent, ChangeEvent, KeyboardEvent, useCallback, useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios, { AxiosError } from "axios";

import { TceppsTestData, isTestingApp } from "../../utils/TestLoadData";

import { tceppsActions } from "../../redux-store/reducer/tcepps_slice";
import { tokenCreatedAction, microformInitializedAction, unmountAction, contextLoadedAction } from "../../redux-store/reducer/paymentSlice";
import { LogService, LOGERRORLEVEL, LOGINFOLEVEL } from "../../service/logService";
import PaymentService from "../../service/paymentService";
import { decryptParamType, getYearsFromToday, isCybersourceTimedout, getTimedoutDummyToken } from "../../utils/calendarUtil";

import classesCpscc from "./MyCanoncpscc.module.css";
import { extractClientLibrary, extractDomain, parseToken } from "../../utils/utils";
import { componentVarObject, ComponentVarProps } from "../../utils/ComponentConstant";

var Buffer = require("buffer/").Buffer; // note: the trailing slash is important!

let developmentEnv = false;
var creditCardMicroNumber: any;
var cvvMicroSecurityCode: any;

const margin20Style = {
  margin: "20px 0",
} as React.CSSProperties;
const creditCardStyle = {
  fontSize: "16px",
  fontFamily: "Proxima Nova",
  paddingLeft: "20px",
  marginRight: "20px",
  height: "20px",
  color: "fieldtext",
  letterSpacing: "normal",
  wordSpacing: "normal",
  lineHeight: "normal",
  textIndent: "0px",
  textShadow: "none",
  display: "inline-block",
  cursor: "text",
  backgroundColor: "#f8f8f8",
  width: "100%",
  margin: "0em",
  borderWidth: "0px",
  borderStyle: "inset",
  borderColor: "-internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133))",
  borderImage: "initial",
} as React.CSSProperties;
const flexStyles = {
  input: {
    cursor: "text",
    color: "#171719",
  },
  "::placeholder": { color: "lightslategrey" },
  ":focus": { color: "black" },
  ":disabled": { cursor: "not-allowed" },
  valid: { color: "#171719" },
  invalid: { color: "#a94442" },
  // ":hover": {
  //   "font-style": "italic",
  // },
} as React.CSSProperties;

const toDay = new Date();
const currentMonthYear = toDay.toLocaleString("en-US", { year: "numeric" }) + toDay.toLocaleString("en-US", { month: "2-digit" });
const currentYear = toDay.toLocaleString("en-US", { year: "numeric" });

const FORMINITIZING = "Browser initializing. Please wait and retry.";
const INVALIDAXIOSMESSAGE = "AxiosError. ";
const INVALIDCARDMESSAGE = "Please enter a valid credit card number.";
const INVALIDCVVMESSAGE = "Please enter a valid CVV Number.";
const INVALIDDATEMESSAGE = "Credit card has expired.";
const PROCESSING = "Processing....  ";

export function MyCanoncpsccComponent(this: any) {
  const dispatch = useDispatch();
  const tceppsState = useSelector((state: any) => state.tcepps);
  const paymentInfo = useSelector((state: any) => state.paymentInfo);

  const [expirationMonth, setExpirationMonth] = useState("");
  const [expirationYear, setExpirationYear] = useState("");
  const [validationMessages, setValidationMessages] = useState("");
  const [selectedCreditCard, setSelectedCreditCard] = useState("");

  const [creditCardNumberDisabled, setCreditCardNumberDisabled] = useState(false);
  const [securityCodeDisabled, setSecurityCodeDisabled] = useState(false);
  const [expirationMonthDisabled, setExpirationMonthDisabled] = useState(false);
  const [expirationYearDisabled, setExpirationYearDisabled] = useState(false);

  const creditCardNumberRef = useRef<HTMLDivElement>(null);
  const securityCodeRef = useRef<HTMLDivElement>(null);
  const expirationMonthRef = useRef<HTMLSelectElement>(null);
  const expirationYearRef = useRef<HTMLSelectElement>(null);
  const submittedProcessingRef = useRef<boolean>(false);
  const validationMessagesRef = useRef<string>("");
  const updateAmountRef = useRef<string>("-1");
  const updateBillingAddressRef = useRef<boolean>(false);
  const updateAddress1Ref = useRef<string>("");
  const updateAddress2Ref = useRef<string>("");
  const updateCityRef = useRef<string>("");
  const updateStateRef = useRef<string>("");
  const updateZipRef = useRef<string>("");
  const logErrorRef = useRef<string>("MYCANONCPSCC Not initialized");
  const termAndConditionAcceptedRef = useRef<boolean>(true);

  const componentObjRef = useRef<ComponentVarProps>(componentVarObject);

  const [creditCardNumberBorderClass, setCreditCardNumberBorderClass] = useState("");
  const [securityCodeBorderClass, setSecurityCodeBorderClass] = useState("");

  const [creditcardValid, setCreditcardValid] = useState(false);
  const [cvvValid, setCvvdValid] = useState(false);
  const [submitForm, setSubmitForm] = useState(false);

  const [decryptParams, setDecryptParams] = useState([] as decryptParamType);
  const [proxyUrl, setProxyUrl] = useState("");

  const { appKey } = useParams();

  function setCreditCardType(newValue: string) {
    switch (newValue) {
      case "VI":
      case "visa":
        componentObjRef.current.glblCreditCardType = "VI";
        break;
      case "MC":
      case "mastercard":
        componentObjRef.current.glblCreditCardType = "MC";
        break;
      case "AX":
      case "amex":
        componentObjRef.current.glblCreditCardType = "AX";
        break;
      case "DI":
      case "discover":
        componentObjRef.current.glblCreditCardType = "DI";
        break;
      default:
        componentObjRef.current.glblCreditCardType = "";
        break;
    }
  }

  const checkCreditCard = useCallback(() => {
    let errorMessage = "";

    if (decryptParams && Object.keys(decryptParams).length > 0 && submitForm) {
      let setFocusSet = false;
      let userMonth = expirationYearRef.current?.value + "" + expirationMonthRef.current?.value;

      if (componentObjRef.current.cardMessage !== "" || !creditcardValid) {
        errorMessage = INVALIDCARDMESSAGE;
        if (creditCardMicroNumber !== undefined && creditCardMicroNumber !== null) {
          creditCardMicroNumber.focus();
        }
        setFocusSet = true;
      }
      if (componentObjRef.current.cvvMessage !== "" || !cvvValid) {
        errorMessage += (errorMessage.length > 0 ? " " : "") + INVALIDCVVMESSAGE;
        if (!setFocusSet) {
          if (cvvMicroSecurityCode !== undefined && cvvMicroSecurityCode !== null) {
            cvvMicroSecurityCode.focus();
          }
        }
        setFocusSet = true;
      }
      if (userMonth < currentMonthYear) {
        errorMessage += (errorMessage.length > 0 ? " " : "") + INVALIDDATEMESSAGE;
        if (!setFocusSet) expirationMonthRef.current?.focus();
        setFocusSet = true;
      }
      if (componentObjRef.current.otherErrMsg !== "") {
        errorMessage += componentObjRef.current.otherErrMsg;
        if (!setFocusSet) {
          if (creditCardMicroNumber !== undefined && creditCardMicroNumber !== null) {
            creditCardMicroNumber.focus();
          }
        }
        setFocusSet = true;
      }
    } else {
      errorMessage = FORMINITIZING;
    }

    if (errorMessage === "") {
      setValidationMessages("");
      validationMessagesRef.current = "";
      componentObjRef.current.isBackgroundRed = false;
      return true;
    } else {
      setValidationMessages(errorMessage);
      validationMessagesRef.current = errorMessage;
      componentObjRef.current.isBackgroundRed = true;
      return false;
    }
  }, [creditcardValid, cvvValid, decryptParams, submitForm]);

  const toggleFields = useCallback((addProfile: string) => {
    const disabled = addProfile === "true" ? false : true;

    setCreditCardNumberDisabled(disabled);
    setExpirationMonthDisabled(disabled);
    setExpirationYearDisabled(disabled);
    setSecurityCodeDisabled(disabled);
    setExpirationMonthDisabled(!disabled);
    setExpirationYearDisabled(!disabled);
  }, []);

  const getLastFourCcAccountNum = (micoFormtoken: string) => {
    const tokenArray = micoFormtoken.split(".");
    let bufferObj = Buffer.from(tokenArray[1], "base64");

    //Encode the Buffer as a utf8 string
    let decodedString = bufferObj.toString("utf8");
    let jsonObject = JSON.parse(decodedString);
    let jti = jsonObject.jti;
    let lastFourDigits = jsonObject.content.paymentInformation.card.number.maskedValue.replaceAll("X", "");
    return [jti, lastFourDigits];
  };

  const invokeProfileAdd = useCallback(
    async (micoFormtoken: string) => {
      // console.log("invokeProfileAdd componentObjRef.current.submitPage" + componentObjRef.current.submitPage);
      if (componentObjRef.current.submitPage && termAndConditionAcceptedRef.current) {
        componentObjRef.current.processingForm = true;
        let expYearMonth = "";
        if (
          expirationMonthRef.current?.value &&
          expirationMonthRef.current?.value.length > 0 &&
          expirationYearRef.current?.value &&
          expirationYearRef.current?.value.length > 0
        ) {
          expYearMonth = expirationYearRef.current?.value + expirationMonthRef.current?.value;
        }

        const [jti, lastFourDigits] = getLastFourCcAccountNum(micoFormtoken);

        let firstName = decryptParams["firstName"];
        let middleName = decryptParams["middleName"];
        let lastName = decryptParams["lastName"];
        let customerName =
          (firstName ? firstName.trim() : " ") + " " + (middleName ? middleName.trim() : " ") + " " + (lastName ? lastName.trim() : " ");
        customerName = customerName.replaceAll("  ", " ");
        customerName = customerName.replaceAll("  ", " ");
        let address1 = decryptParams["address1"];
        let address2 = decryptParams["address2"];
        let city = decryptParams["city"];
        let state = decryptParams["state"];
        let zip = decryptParams["zip"];
        let proxyUrl = decryptParams["tcepps.proxyUrl"];
        let amount = decryptParams["amount"];
        if (updateAmountRef.current !== "-1") {
          amount = updateAmountRef.current;
        }
        if (updateBillingAddressRef.current) {
          address1 = updateAddress1Ref.current;
          address2 = updateAddress2Ref.current;
          city = updateCityRef.current;
          state = updateStateRef.current;
          zip = updateZipRef.current;
        }
        let email = decryptParams["email"];
        if (!(email && email.length > 0)) {
          // email = DEFUSEREMAIL;
          email = decryptParams["customerEmail"];
        }
        if (email && email.length > 0) {
          email = email.trim();
        }
        let countryCd = decryptParams["country"];
        if (!(countryCd && countryCd.length > 0)) {
          countryCd = "US";
        }
        if (!address1) {
          address1 = address2;
          address2 = "";
        }

        let orbitalConnectionUsername = decryptParams["pno.orbitalConnectionUsername"];
        let orbitalConnectionPassword = decryptParams["pno.orbitalConnectionPassword"];
        let switchSoloIssueNum = decryptParams["pno.switchSoloIssueNum"];
        let switchSoloCardStartDate = decryptParams["pno.switchSoloCardStartDate"];
        let authenticationECIInd = decryptParams["pno.authenticationECIInd"];
        if (!orbitalConnectionUsername) {
          orbitalConnectionUsername = decryptParams["orbitalConnectionUsername"];
        }
        if (!orbitalConnectionPassword) {
          orbitalConnectionPassword = decryptParams["orbitalConnectionPassword"];
        }
        if (!switchSoloIssueNum) {
          switchSoloIssueNum = decryptParams["switchSoloIssueNum"];
        }
        if (!switchSoloCardStartDate) {
          switchSoloCardStartDate = decryptParams["switchSoloCardStartDate"];
        }
        if (!authenticationECIInd) {
          authenticationECIInd = decryptParams["authenticationECIInd"];
        }

        if (developmentEnv) {
          let address = address1;
          if (address2) {
            address = address + " " + address2;
          }
          if (address) {
            if (address.toLowerCase().indexOf("testdonothonorfailure") >= 0) {
              amount = "40100";
            }
            if (address.toLowerCase().indexOf("testaddressfailure") >= 0) {
              amount = "283600";
            }
            if (address.toLowerCase().indexOf("testcvvfailure") >= 0) {
              amount = "700500";
              // cvv = "111";
            }
            if (address.toLowerCase().indexOf("testapifailure") >= 0) {
              email = "abcd@";
            }
            if (address.toLowerCase().indexOf("testinsufficientfund") >= 0) {
              amount = "250900"; //2509 2521
            }
            if (address.toLowerCase().indexOf("testinsufficientfund2") >= 0) {
              amount = "252100"; //2509 2521
            }
          }
        }
        // amount = "2521.00";
        const mycanoncpsObj = {
          "pno.ccAccountNum": "TT=" + jti,
          "pno.ccExp": expYearMonth,
          "pno.ccCardVerifyNum": "",
          "pno.avsName": customerName ? customerName.toUpperCase().trim() : "",
          "pno.avsAddress1": address1 ? address1.toUpperCase().trim() : "",
          "pno.avsAddress2": address2 ? address2.toUpperCase().trim() : "",
          "pno.avsCity": city ? city.toUpperCase().trim() : "",
          "pno.avsState": state ? state.toUpperCase().trim() : "",
          "pno.avsZip": zip ? zip.toUpperCase().trim() : "",
          "pno.amount": !amount ? "100" : amount,
          "pno.orderID": decryptParams["orderNumber"],
          "tcepps.cardBrand": componentObjRef.current.glblCreditCardType,
          "tcepps.lastFourDigits": lastFourDigits,
          "pno.avsCountryCode": countryCd ? countryCd.toUpperCase().trim() : countryCd,
          "pno.customerEmail": email,
          "pno.orbitalConnectionUsername": orbitalConnectionUsername ? orbitalConnectionUsername : "",
          "pno.orbitalConnectionPassword": orbitalConnectionPassword ? orbitalConnectionPassword : "",
          "pno.switchSoloIssueNum": switchSoloIssueNum ? switchSoloIssueNum : "",
          "pno.switchSoloCardStartDate": switchSoloCardStartDate ? switchSoloCardStartDate : "",
          "pno.authenticationECIInd": authenticationECIInd ? authenticationECIInd : "",
          "tcepps.proxyUrl": proxyUrl,
          "tcepps.serviceOperation": "newOrder",
          "tcepps.app": tceppsState.app.toUpperCase().trim(),
          "tcepps.token": tceppsState.token,
          "tcepps.sign": tceppsState.sign,
        };
        toggleFields("false");
        PaymentService.submitMycanoncpscc(mycanoncpsObj)
          .then((resp) => {
            window.open(resp.data, "_self");
            // console.log(resp.data);
            // console.log(mycanoncpsObj);
          })
          .catch((err: Error | AxiosError) => {
            // console.log(err);
            if (axios.isAxiosError(err)) {
              // Access to config, request, and response
              setValidationMessages(INVALIDAXIOSMESSAGE + "call to submitMycanoncpscc failed. " + err.message + ". Please check console.");
              validationMessagesRef.current =
                INVALIDAXIOSMESSAGE + "call to submitMycanoncpscc failed. " + err.message + ". Please check console.";
            } else {
              // Just a stock error
              setValidationMessages("Error in submitMycanoncpscc. " + err.message + ". Please check console.");
              validationMessagesRef.current = "Error in submitMycanoncpscc. " + err.message + ". Please check console.";
            }
            componentObjRef.current.isBackgroundRed = true;
            LogService.logMessage(
              logErrorRef.current,
              LOGERRORLEVEL,
              "Call to submit form submitMycanoncpsap failed:" + (err ? JSON.stringify(err.message) : "null")
            );
          });
      }
    },
    [toggleFields, tceppsState.app, tceppsState.token, tceppsState.sign, decryptParams]
  );

  const createCybersourceToken = useCallback(async () => {
    if (isCybersourceTimedout(toDay)) {
      componentObjRef.current.isBackgroundRed = true;
      (document.getElementById("validationMessages") as HTMLInputElement).value = PROCESSING;
      validationMessagesRef.current = PROCESSING;
      const { token, jti, lastFourDigits } = getTimedoutDummyToken();
      dispatch(tokenCreatedAction({ token, jti, lastFourDigits }));
    } else {
      componentObjRef.current.cardMessage = "";
      componentObjRef.current.cvvMessage = "";

      const options = {
        expirationMonth: expirationMonthRef.current?.value,
        expirationYear: expirationYearRef.current?.value,
      };

      // async function createMicroformToken(options: any) {
      await paymentInfo.microform.createToken(options, (err: Error, token: any) => {
        if (err) {
          // console.log(err);
          componentObjRef.current.otherErrMsg = "";
          componentObjRef.current.cardMessage = "";
          componentObjRef.current.cvvMessage = "";
          let foundCardMessage = true;
          // handle error
          // @ts-ignore
          switch (err.reason) {
            case "CREATE_TOKEN_NO_FIELDS_LOADED":
              componentObjRef.current.otherErrMsg = "Token creation error, no fields have been loaded." + err.message;
              break;
            case "CREATE_TOKEN_TIMEOUT":
              componentObjRef.current.otherErrMsg = "createToken call was unable to proceed." + err.message;
              break;
            case "CREATE_TOKEN_XHR_ERROR":
              componentObjRef.current.otherErrMsg = "Network error when attempting to create a token." + err.message;
              break;
            case "CREATE_TOKEN_NO_FIELDS":
              componentObjRef.current.otherErrMsg = "Data fields are unavailable for collection." + err.message;
              break;
            case "CREATE_TOKEN_VALIDATION_PARAMS":
              componentObjRef.current.otherErrMsg = "Issue with parameters supplied to createToken." + err.message;
              break;
            case "CREATE_TOKEN_VALIDATION_FIELDS":
              // @ts-ignore
              if (err.details && err.details.length > 0) {
                // @ts-ignore
                if (err.details.length > 1) {
                  componentObjRef.current.cardMessage = INVALIDCARDMESSAGE;
                  componentObjRef.current.cvvMessage = INVALIDCVVMESSAGE;
                  // @ts-ignore
                } else if (err.details[0].location === "number") {
                  componentObjRef.current.cardMessage = INVALIDCARDMESSAGE;
                  // @ts-ignore
                } else if (err.details[0].location === "securityCode") {
                  componentObjRef.current.cvvMessage = INVALIDCVVMESSAGE;
                  foundCardMessage = false;
                } else {
                  componentObjRef.current.otherErrMsg = err.message;
                }
              } else {
                componentObjRef.current.otherErrMsg = err.message;
              }
              break;
            case "CREATE_TOKEN_VALIDATION_SERVERSIDE":
              componentObjRef.current.otherErrMsg = "Server-side validation rejects the createToken request." + err.message;
              break;
            case "CREATE_TOKEN_UNABLE_TO_START":
              componentObjRef.current.otherErrMsg = "Loaded field was able to handle the createToken request." + err.message;
              break;
            default:
              componentObjRef.current.otherErrMsg = "Unknown error:" + err.message;
              break;
          }
          if (!foundCardMessage) componentObjRef.current.cardMessage = "";
          checkCreditCard();
        } else {
          componentObjRef.current.isBackgroundRed = true;
          (document.getElementById("validationMessages") as HTMLInputElement).value = PROCESSING;
          validationMessagesRef.current = PROCESSING;
          const { jti, lastFourDigits } = parseToken(token);
          dispatch(tokenCreatedAction({ token, jti, lastFourDigits }));
        }
      });
    }
  }, [paymentInfo.microform, dispatch, checkCreditCard]);

  // useEffect when input parameters are decrypted.
  useEffect(() => {
    if (decryptParams && Object.keys(decryptParams).length > 0 && submitForm) {
      setValidationMessages("");
      validationMessagesRef.current = "";
      componentObjRef.current.isBackgroundRed = false;
    }
  }, [decryptParams, submitForm]);

  // useEffect when new token is generated submit form
  useEffect(() => {
    // console.log("useEffect submit form...2");
    // console.log("componentObjRef.current.processingForm:" + componentObjRef.current.processingForm + " paymentInfo.token:" + paymentInfo.token);
    if (!componentObjRef.current.processingForm && termAndConditionAcceptedRef.current && paymentInfo.token) {
      if (checkCreditCard()) {
        // console.log("componentObjRef.current.processingForm after checkCreditCard");
        componentObjRef.current.isBackgroundRed = true;
        setValidationMessages(PROCESSING);
        (document.getElementById("validationMessages") as HTMLInputElement).value = PROCESSING;
        validationMessagesRef.current = PROCESSING;
        invokeProfileAdd(paymentInfo.token);
      }
    }
  }, [paymentInfo.token, checkCreditCard, invokeProfileAdd]);

  const windowMessageHandler = useCallback(
    (event: MessageEvent) => {
      // console.log(event.data);
      // console.log("messagehandler initializationComplete:" + initializationComplete);
      if (!submitForm && event.data !== undefined && event.data === "processTransaction") {
        let errorMessage = FORMINITIZING;
        setValidationMessages(errorMessage);
        validationMessagesRef.current = errorMessage;
        componentObjRef.current.isBackgroundRed = true;
      } else if (submitForm && event.data !== undefined && event.data === "processTransaction") {
        submittedProcessingRef.current = true;
        if (checkCreditCard()) {
          // check the origin of the data!
          if (
            event.origin.startsWith("https://") &&
            (event.origin.indexOf(".usa.canon.com") > 0 ||
              event.origin.indexOf(".cusa.canon.com") > 0 ||
              event.origin.indexOf(".americas.canon.com") > 0) &&
            termAndConditionAcceptedRef.current
          ) {
            componentObjRef.current.submitPage = true;
            createCybersourceToken();
          } else if (termAndConditionAcceptedRef.current && event.origin.startsWith("http://") && event.origin.indexOf("localhost") > 0) {
            componentObjRef.current.submitPage = true;
            createCybersourceToken();
          } else {
            return;
          }
        }
      } else if (submitForm && event.data && event.data.type && event.data.type === "changeHoldAmount" && event.data.payload) {
        // Ticket# 1079084: Update amount
        LogService.logMessage(logErrorRef.current, LOGINFOLEVEL, "changeHoldAmount" + event.data.payload);
        if (
          event.origin.startsWith("https://") &&
          (event.origin.indexOf(".usa.canon.com") > 0 ||
            event.origin.indexOf(".cusa.canon.com") > 0 ||
            event.origin.indexOf(".americas.canon.com") > 0)
        ) {
          updateAmountRef.current = event.data.payload;
        } else if (event.origin.startsWith("http://") && event.origin.indexOf("localhost") > 0) {
          updateAmountRef.current = event.data.payload;
        }
      } else if (submitForm && event.data && event.data.type && event.data.type === "changeBillingAddress" && event.data.payload) {
        // Ticket# 1079084: Update billing address
        LogService.logMessage(logErrorRef.current, LOGINFOLEVEL, "changeBillingAddress" + JSON.stringify(event.data.payload));
        if (
          event.origin.startsWith("https://") &&
          (event.origin.indexOf(".usa.canon.com") > 0 ||
            event.origin.indexOf(".cusa.canon.com") > 0 ||
            event.origin.indexOf(".americas.canon.com") > 0)
        ) {
          updateBillingAddressRef.current = true;
        } else if (event.origin.startsWith("http://") && event.origin.indexOf("localhost") > 0) {
          updateBillingAddressRef.current = true;
        }
        if (updateBillingAddressRef.current) {
          updateAddress1Ref.current = event.data.payload.address1 ? event.data.payload.address1 : "";
          updateAddress2Ref.current = event.data.payload.address2 ? event.data.payload.address2 : "";
          updateCityRef.current = event.data.payload.city ? event.data.payload.city : "";
          updateStateRef.current = event.data.payload.st ? event.data.payload.st : "";
          updateZipRef.current = event.data.payload.zip ? event.data.payload.zip : "";
        }
      } else if (submitForm && event.data && event.data.type && event.data.type === "changeAcceptTermAndCondition" && event.data.payload) {
        // Ticket# 1079084: Update billing address
        LogService.logMessage(logErrorRef.current, LOGINFOLEVEL, "changeAcceptTermAndCondition" + JSON.stringify(event.data.payload));
        let acceptedEvent = false;
        if (
          event.origin.startsWith("https://") &&
          (event.origin.indexOf(".usa.canon.com") > 0 ||
            event.origin.indexOf(".cusa.canon.com") > 0 ||
            event.origin.indexOf(".americas.canon.com") > 0)
        ) {
          acceptedEvent = true;
        } else if (event.origin.startsWith("http://") && event.origin.indexOf("localhost") > 0) {
          acceptedEvent = true;
        }
        if (acceptedEvent) {
          let isAcceptTermAndCondition = event.data.payload.isAcceptTermAndCondition;
          if (isAcceptTermAndCondition) {
            isAcceptTermAndCondition = isAcceptTermAndCondition.toLocaleLowerCase();
            if (isAcceptTermAndCondition === "y" || isAcceptTermAndCondition === "yes" || isAcceptTermAndCondition === "true") {
              termAndConditionAcceptedRef.current = true;
            } else {
              termAndConditionAcceptedRef.current = false;
            }
          }
        }
      } else if (
        event.data &&
        (event.data as any).data !== undefined &&
        (event.data as any).data &&
        ((event.data as any).data as any).focusShift !== undefined &&
        ((event.data as any).data as any).focusShift &&
        (event.data as any).event !== undefined &&
        (event.data as any).event === "blur" &&
        event.currentTarget &&
        (event.currentTarget as any).name !== undefined &&
        (event.currentTarget as any).name &&
        (event.currentTarget as any).name === "creditCardFormIFrame"
      ) {
        if (((event.data as any).data as any).focusShift === "next") {
          if (componentObjRef.current.microFromFocusField === "creditCardNumber") {
            componentObjRef.current.microFromFocusField = "";
            if (cvvMicroSecurityCode !== undefined && cvvMicroSecurityCode !== null) {
              cvvMicroSecurityCode.focus();
            }
          } else {
            componentObjRef.current.microFromFocusField = "";
            expirationMonthRef.current?.focus();
          }
        } else if (((event.data as any).data as any).focusShift === "previous") {
          if (componentObjRef.current.microFromFocusField === "securityCode") {
            componentObjRef.current.microFromFocusField = "";
            if (creditCardMicroNumber !== undefined && creditCardMicroNumber !== null) {
              creditCardMicroNumber.focus();
            }
          } else {
            componentObjRef.current.microFromFocusField = "";
            expirationYearRef.current?.focus();
          }
        }
      }
    },
    [createCybersourceToken, checkCreditCard, submitForm]
  );

  // useEffect for windows message
  useEffect(() => {
    // console.log("useEffect addEventListener...3");
    if (paymentInfo.isSuccessful) {
      window.addEventListener("message", windowMessageHandler, false);
    }

    return () => {
      window.removeEventListener("message", windowMessageHandler);
    };
  }, [windowMessageHandler, paymentInfo.isSuccessful]);

  // decrypt the input parameters
  const decryptInputParameterAsync = useCallback(async (app: string, encryptedRequestKey: string, encryptedRequest: string) => {
    let decryptParamsResponse: any;

    try {
      const response = await PaymentService.getDecryptRequest(app, encryptedRequestKey, encryptedRequest);
      decryptParamsResponse = response.data;
    } catch (err: any) {
      // console.log(err);
      if (axios.isAxiosError(err)) {
        // Access to config, request, and response
        let errorMessage = INVALIDAXIOSMESSAGE + "call to getDecryptRequest failed. " + err.message + ". Please check console..";
        setValidationMessages(errorMessage);
        validationMessagesRef.current = errorMessage;
      } else {
        // Just a stock error
        let errorMessage = "Error in getDecryptRequest. " + err.message + ". Please check console..";
        setValidationMessages(errorMessage);
        validationMessagesRef.current = errorMessage;
      }
      LogService.logMessage(logErrorRef.current, LOGERRORLEVEL, "PaymentService.getDecryptRequest call failed. " + err.message);
      // componentObjRef.current.isBackgroundRed = true;
    }

    if (decryptParamsResponse && Object.keys(decryptParamsResponse).length > 0) {
      // check for proxy
      let iFrameUrl = document.referrer;
      if (iFrameUrl === undefined || iFrameUrl.trim().length === 0) {
        setProxyUrl(decryptParamsResponse["tcepps.proxyUrl"]);
      }

      setDecryptParams(decryptParamsResponse);
      let inputExpirationDate = decryptParamsResponse["expirationDate"];
      if (inputExpirationDate && inputExpirationDate.length === 7) {
        setExpirationMonth(inputExpirationDate.substring(0, 2));
        setExpirationYear(inputExpirationDate.substring(3));
      }
      let cardType = decryptParamsResponse["cardType"];
      switch (cardType) {
        case "VI":
          setCreditCardType(cardType);
          setSelectedCreditCard(cardType);
          break;
        case "MC":
          setCreditCardType(cardType);
          setSelectedCreditCard(cardType);
          break;
        case "AX":
          setCreditCardType(cardType);
          setSelectedCreditCard(cardType);
          break;
        case "DS":
          setCreditCardType("DI");
          setSelectedCreditCard("DI");
          break;
        case "DI":
          setCreditCardType(cardType);
          setSelectedCreditCard(cardType);
          break;
      }
      // const initialCCNumber = decryptParamsResponse["lastFourDigitsOfCard"];
      // if (initialCCNumber) {
      // setStoredCardMesssage("stored card: *******" + initialCCNumber);
      // }
      // const initialCardType = decryptParamsResponse["cardType"];
      // if (initialCardType) {
      // setStoredCardTypeMesssage("stored card: " + initialCardType);
      // setSelectedCreditCard(initialCardType);
      // }

      // check if its called from testing app
      if (isTestingApp(decryptParamsResponse["app.TESTINGAPP"])) {
        developmentEnv = true;
        setExpirationMonth(TceppsTestData.ToBeAppTestData.MyCanoncpscc["ppa.test.expirationMonth"]);
        setExpirationYear(TceppsTestData.ToBeAppTestData.MyCanoncpscc["ppa.test.expirationYear"]);
      }
      logErrorRef.current = "MYCANONCPSCC Order# " + decryptParamsResponse["orderNumber"];

      let isAcceptTermAndCondition = decryptParamsResponse["isAcceptTermAndCondition"];
      if (isAcceptTermAndCondition) {
        isAcceptTermAndCondition = isAcceptTermAndCondition.toLocaleLowerCase();
        if (isAcceptTermAndCondition === "y" || isAcceptTermAndCondition === "yes" || isAcceptTermAndCondition === "true") {
          termAndConditionAcceptedRef.current = true;
        } else {
          termAndConditionAcceptedRef.current = false;
        }
      }
    } else {
      let errorMessage = "Customer details not available. Please close and retry.";
      setValidationMessages(errorMessage);
      validationMessagesRef.current = errorMessage;
      LogService.logMessage(logErrorRef.current, LOGERRORLEVEL, "Customer details not available. Please close and retry.");
    }
  }, []);

  // useEffect if screen/page input parameters change. Dispatch state change
  useEffect(() => {
    // console.log("useEffect initialload...4");

    const appKey0 = Buffer.from(appKey ? appKey : "", "base64");
    const appKey1 = appKey0.toString("utf8");
    const mydata = JSON.parse(appKey1);

    let iFrameUrl = document.referrer;
    if (iFrameUrl && iFrameUrl.trim().length > 0) {
      setProxyUrl(iFrameUrl);
    }

    let requestParameter = {
      app: "",
      token: "",
      sign: "",
      serviceOperation: "",
      encryptedRequest: "",
      encryptedRequestKey: "",
      enableNewCrCardFlag: "",
      addProfile: "",
      cpgmuiApiUrl: "",
      cpgmuiEnv: "",
    };

    // console.log("params:" + Object.keys(mydata));

    const getPortNumber = () => {
      const windowsUrl = window.location.href;
      const searchParams = windowsUrl.indexOf("//") + 2;
      const firstSlashIndex = windowsUrl.indexOf("/", searchParams);
      const colonIndex = windowsUrl.indexOf(":", searchParams);
      if (firstSlashIndex > 0 && colonIndex > 0) {
        return windowsUrl.substring(colonIndex + 1, firstSlashIndex);
      } else {
        return "";
      }
    };

    //find port if it exits
    const urlPortNumber = getPortNumber();

    Object.keys(mydata).forEach((key) => {
      switch (key) {
        case "tcepps.app":
          requestParameter.app = mydata[key];
          break;
        case "tcepps.token":
          requestParameter.token = mydata[key];
          break;
        case "tcepps.sign":
          requestParameter.sign = mydata[key];
          break;
        case "tcepps.serviceOperation":
          requestParameter.serviceOperation = mydata[key];
          break;
        case "tcepps.encryptedRequest":
          requestParameter.encryptedRequest = mydata[key];
          break;
        case "tcepps.encryptedRequestKey":
          requestParameter.encryptedRequestKey = mydata[key];
          break;
        case "tcepps.enableNewCrCardFlag":
          requestParameter.enableNewCrCardFlag = mydata[key];
          break;
        case "tcepps.addProfile":
          requestParameter.addProfile = mydata[key];
          break;
        case "cpgmuiApiUrl":
          requestParameter.cpgmuiApiUrl = mydata[key] + "/TCEPPS/jsp/tceppsHtmlFormInterface.jsp?tceppsApp=";
          if (urlPortNumber !== "") {
            let backendPortNumber = getPortNumber();
            if (backendPortNumber !== urlPortNumber) {
              requestParameter.cpgmuiApiUrl = requestParameter.cpgmuiApiUrl.replace(backendPortNumber, urlPortNumber);
            }
          }
          break;
        case "cpgmuiEnv":
          requestParameter.cpgmuiEnv = mydata[key];
          if (requestParameter.cpgmuiEnv.toLowerCase() === "development" || requestParameter.cpgmuiEnv.toLowerCase() === "qa") {
            developmentEnv = true;
          }
          break;
      }
    });
    requestParameter.cpgmuiApiUrl += requestParameter.app;

    dispatch(
      tceppsActions.initializeTceppsAction({
        app: requestParameter.app,
        token: requestParameter.token,
        sign: requestParameter.sign,
        serviceOperation: requestParameter.serviceOperation,
        encryptedRequest: requestParameter.encryptedRequest,
        encryptedRequestKey: requestParameter.encryptedRequestKey,
        enableNewCrCardFlag: requestParameter.enableNewCrCardFlag,
        cpgmuiApiUrl: requestParameter.cpgmuiApiUrl,
        cpgmuiEnv: requestParameter.cpgmuiEnv,
      })
    );

    try {
      getYearsFromToday(11, document.getElementById("expirationYear") as HTMLSelectElement, false);
    } catch (error) {}

    setExpirationMonth("01");
    setExpirationYear(currentYear);
    // if (developmentEnv && isDevUrl(window.location.href)) {
    //   setExpirationMonth(TceppsTestData.ToBeAppTestData.MyCanoncpscc["ppa.test.expirationMonth"]);
    //   setExpirationYear(TceppsTestData.ToBeAppTestData.MyCanoncpscc["ppa.test.expirationYear"]);
    // }
    setCreditCardType("");
    setSelectedCreditCard("");

    setValidationMessages("");
    validationMessagesRef.current = "";
    componentObjRef.current.isBackgroundRed = false;

    decryptInputParameterAsync(
      requestParameter.app.toUpperCase().trim(),
      requestParameter.encryptedRequestKey,
      requestParameter.encryptedRequest
    );
  }, [appKey, dispatch, decryptInputParameterAsync]);

  const updateValidationMessages = useCallback(
    (action: string, pdateString: string) => {
      if (submittedProcessingRef.current) {
        let errMsg = validationMessages;
        if (action === "add" || action === "addmessage") {
          if (errMsg!.indexOf(pdateString) < 0) {
            let updatedMsg = "";
            errMsg = errMsg?.length === 0 ? pdateString : errMsg + " " + pdateString;
            if (errMsg.indexOf(INVALIDCARDMESSAGE) >= 0) {
              updatedMsg = INVALIDCARDMESSAGE;
            }
            if (errMsg.indexOf(INVALIDCVVMESSAGE) >= 0) {
              updatedMsg += (updatedMsg?.length > 0 ? " " : "") + INVALIDCVVMESSAGE;
            }
            if (errMsg.indexOf(INVALIDDATEMESSAGE) >= 0) {
              updatedMsg += (updatedMsg?.length > 0 ? " " : "") + INVALIDDATEMESSAGE;
            }
            setValidationMessages(updatedMsg);
            validationMessagesRef.current = updatedMsg;
            componentObjRef.current.isBackgroundRed = true;
          }
        } else {
          if (errMsg.indexOf(pdateString) >= 0) {
            errMsg = errMsg?.replace(" " + pdateString, "");
            errMsg = errMsg?.replace(pdateString, "");
            setValidationMessages(errMsg);
            validationMessagesRef.current = errMsg;
            if (errMsg.trim().length === 0) {
              componentObjRef.current.isBackgroundRed = false;
            }
          }
        }
      }
    },
    [validationMessages]
  );

  // useEffect when form get loaded. setup microform
  useEffect(() => {
    // console.log("useEffect microform...5");
    const updateValidationMessages = (action: string, pdateString: string) => {
      if (submittedProcessingRef.current) {
        let errMsg = validationMessagesRef.current;
        if (errMsg === undefined) {
          errMsg = "";
        }
        if (action === "add" || action === "addmessage") {
          if (errMsg!.indexOf(pdateString) < 0) {
            let updatedMsg = "";
            errMsg = errMsg?.length === 0 ? pdateString : errMsg + " " + pdateString;
            if (errMsg.indexOf(INVALIDCARDMESSAGE) >= 0) {
              updatedMsg = INVALIDCARDMESSAGE;
            }
            if (errMsg.indexOf(INVALIDCVVMESSAGE) >= 0) {
              updatedMsg += (updatedMsg?.length > 0 ? " " : "") + INVALIDCVVMESSAGE;
            }
            if (errMsg.indexOf(INVALIDDATEMESSAGE) >= 0) {
              updatedMsg += (updatedMsg?.length > 0 ? " " : "") + INVALIDDATEMESSAGE;
            }
            setValidationMessages(updatedMsg);
            validationMessagesRef.current = updatedMsg;
            componentObjRef.current.isBackgroundRed = true;
          }
        } else {
          if (errMsg!.indexOf(pdateString) >= 0) {
            errMsg = errMsg?.replace(" " + pdateString, "");
            errMsg = errMsg?.replace(pdateString, "");
            setValidationMessages(errMsg);
            validationMessagesRef.current = errMsg;
            if (errMsg.trim().length === 0) {
              componentObjRef.current.isBackgroundRed = false;
            }
          }
        }
      }
    };

    function selectGivenCardType(cardName: string, cardValid: boolean, cardEmpty: boolean) {
      let varCreditCardType = "";
      let detectedCard = false;
      if (cardName.length > 0) {
        switch (cardName) {
          case "VI":
          case "visa":
            varCreditCardType = "visa";
            detectedCard = true;
            break;
          case "MC":
          case "mastercard":
            varCreditCardType = "mastercard";
            detectedCard = true;
            break;
          case "AX":
          case "amex":
            varCreditCardType = "amex";
            detectedCard = true;
            break;
          case "DI":
          case "discover":
            varCreditCardType = "discover";
            detectedCard = true;
            break;
        }
      }

      if (detectedCard) {
        setCreditCardType(varCreditCardType);
        setSelectedCreditCard(varCreditCardType);
      } else {
        setCreditCardType("");
        setSelectedCreditCard("");
      }

      let errMessage = "Credit Card# needs to be entered.";
      if (!detectedCard) {
        errMessage = "Please enter a visa or a mastercard or amex or discover card#.";
        // if (!cardEmpty) {
        //   setStoredCardMesssage("");
        //   setStoredCardTypeMesssage("");
        // }
      } else if (cardEmpty) {
        errMessage = "Credit Card# needs to be entered.";
      } else if (!cardValid) {
        errMessage = "Please enter a valid Credit card#.";
      } else if (cardValid) {
        errMessage = "";
        // setStoredCardMesssage("");
        // setStoredCardTypeMesssage("");
      }
      setCreditcardValid(cardValid && detectedCard);
      componentObjRef.current.cardMessage = errMessage;
      updateValidationMessages(componentObjRef.current.cardMessage.length > 0 ? "addmessage" : "deletemessage", INVALIDCARDMESSAGE);
    }

    const loadCybersourceJS = (captureContext: any, clientLibrary: string, clientLibraryIntegrity: string, callback: (cc: any) => any) => {
      const script = document.createElement("script");
      script.type = "text/javascript";
      // script.src = config.cybersource.microformJs;
      script.src = clientLibrary;
      script.integrity = clientLibraryIntegrity;
      script.crossOrigin = "anonymous";
      document.body.appendChild(script);
      script.onload = () => {
        callback(captureContext);
      };
    };

    const initializeMicroform = async (captureContext: any) => {
      // @ts-ignore
      const flex = await new window.Flex(captureContext);
      const microform = await flex.microform({ styles: flexStyles });
      creditCardMicroNumber = await microform.createField("number", { placeholder: "Card Number *" });
      cvvMicroSecurityCode = await microform.createField("securityCode", { placeholder: "CVV *" });
      await creditCardMicroNumber.load("#creditCardNumber");
      await cvvMicroSecurityCode.load("#securityCode");
      dispatch(microformInitializedAction(microform));

      if (creditCardMicroNumber !== undefined && creditCardMicroNumber !== null) {
        //add listener
        creditCardMicroNumber.on("change", function (data: any) {
          if (data.card && data.card.length > 0) {
            selectGivenCardType(data.card[0].name, data.valid, data.empty);
          } else {
            selectGivenCardType("", data.valid, data.empty);
          }
        });
        creditCardMicroNumber.on("load", function (data: any) {
          creditCardMicroNumber.focus();
          setSubmitForm(true);
        });
        creditCardMicroNumber.on("focus", function (data: any) {
          componentObjRef.current.microFromFocusField = "creditCardNumber";
          setCreditCardNumberBorderClass(`${classesCpscc.Search__SearchInput_focus}`);
        });
        creditCardMicroNumber.on("blur", function (data: any) {
          setCreditCardNumberBorderClass("");
        });
      }
      if (cvvMicroSecurityCode !== undefined && cvvMicroSecurityCode !== null) {
        cvvMicroSecurityCode.on("focus", function (data: any) {
          componentObjRef.current.microFromFocusField = "securityCode";
          setSecurityCodeBorderClass(`${classesCpscc.Search__SearchInput_focus}`);
        });
        cvvMicroSecurityCode.on("blur", function (data: any) {
          setSecurityCodeBorderClass("");
        });
        cvvMicroSecurityCode.on("change", function (data: any) {
          if (data.empty || !data.valid) {
            updateValidationMessages("addmessage", INVALIDCVVMESSAGE);
            setCvvdValid(false);
          } else if (data.valid) {
            updateValidationMessages("removemessage", INVALIDCVVMESSAGE);
            setCvvdValid(true);
          }
        });
        // cvvMicroSecurityCode.on("inputSubmitRequest", function (data: any) {
        //   expirationMonthRef.current?.focus();
        // });
      }
    };

    const getCaptureContext = async () => {
      await PaymentService.getCaptureContext(extractDomain(proxyUrl))
        .then((resp) => {
          // const captureContext = resp.data.data;
          const captureContext = resp.data.captureContext;
          const { clientLibrary, clientLibraryIntegrity } = extractClientLibrary(resp.data.json);
          dispatch(contextLoadedAction(captureContext));
          // @ts-ignore
          if (!window.Flex) {
            loadCybersourceJS(captureContext, clientLibrary, clientLibraryIntegrity, initializeMicroform);
          } else {
            initializeMicroform(captureContext);
          }
          return () => dispatch(unmountAction);
        })
        .catch((err: Error | AxiosError) => {
          // console.log(err);
          if (axios.isAxiosError(err)) {
            setValidationMessages(INVALIDAXIOSMESSAGE + "call to initialize microForm failed. " + err.message + ". Please check console.");
            validationMessagesRef.current =
              INVALIDAXIOSMESSAGE + "call to initialize microForm failed. " + err.message + ". Please check console.";
          } else {
            setValidationMessages("Initialize microForm failed. " + err.message + ". Please check console.");
            validationMessagesRef.current = "Initialize microForm failed. " + err.message + ". Please check console.";
          }
          componentObjRef.current.isBackgroundRed = true;
          LogService.logMessage(logErrorRef.current, LOGERRORLEVEL, "Initialize microForm failed. " + err.message);
        });
    };
    if (proxyUrl && proxyUrl.length > 0) {
      getCaptureContext();
    }
  }, [dispatch, proxyUrl]);

  const onChangeHandler = (identifier: string, event: ChangeEvent & { target: HTMLInputElement | HTMLSelectElement }) => {
    switch (identifier) {
      case "expirationMonth":
        setExpirationMonth(event.target.value);
        break;
      case "expirationYear":
        setExpirationYear(event.target.value);
        break;
    }
    let userMonth = expirationYearRef.current?.value + "" + expirationMonthRef.current?.value;
    if (userMonth < currentMonthYear) {
      updateValidationMessages("addmessage", INVALIDDATEMESSAGE);
    } else {
      updateValidationMessages("removemessage", INVALIDDATEMESSAGE);
    }
  };

  const onBlurHandler = (identifier: string, event: FocusEvent & { target: HTMLSelectElement }) => {
    switch (identifier) {
      case "expirationMonth":
      case "expirationYear":
        if (identifier === "expirationMonth") {
          setExpirationMonth(event.target.value);
        } else {
          setExpirationYear(event.target.value);
        }
        break;
    }
  };

  const onKeyDownHandler = (identifier: string, event: KeyboardEvent<HTMLSelectElement>) => {
    if (event.key === "Tab") {
      if (identifier === "expirationMonth" && event.shiftKey) {
        if (cvvMicroSecurityCode !== undefined && cvvMicroSecurityCode !== null) {
          cvvMicroSecurityCode.focus();
        }
      } else if (identifier === "expirationYear" && !event.shiftKey) {
        if (creditCardMicroNumber !== undefined && creditCardMicroNumber !== null) {
          creditCardMicroNumber.current?.focus();
        }
      }
    }
  };

  return (
    <>
      <form id="tcepps" name="tcepps" action={tceppsState.cpgmuiApiUrl} target="_self">
        <input id="app" name="app" type="hidden" value={tceppsState.app} />
        <input id="token" name="token" type="hidden" value={tceppsState.token} />
        <input id="sign" name="sign" type="hidden" value={tceppsState.sign} />
        <input id="serviceOperation" name="serviceOperation" type="hidden" value="" />
        <input id="encryptedRequest" name="encryptedRequest" type="hidden" value={tceppsState.encryptedRequest} />
        <input id="encryptedRequestKey" name="encryptedRequestKey" type="hidden" value={tceppsState.encryptedRequestKey} />
        <input id="lastFourCcAccountNum" name="lastFourCcAccountNum" type="hidden" value="" />
        <input id="proxyUrl" name="proxyUrl" type="hidden" value="" />
      </form>
      <form id="pno" name="pno">
        <input id="ccAccountNum" name="ccAccountNum" type="hidden" value="" />
        <input id="ccExp" name="ccExp" type="hidden" value="" />
        <input id="ccCardVerifyNum" name="ccCardVerifyNum" type="hidden" value="" />
        <input id="avsName" name="avsName" type="hidden" value="" />
        <input id="avsAddress1" name="avsAddress1" type="hidden" value="" />
        <input id="avsAddress2" name="avsAddress2" type="hidden" value="" />
        <input id="avsCity" name="avsCity" type="hidden" value="" />
        <input id="avsState" name="avsState" type="hidden" value="" />
        <input id="avsZip" name="avsZip" type="hidden" value="" />
        <input id="avsCountryCode" name="avsCountryCode" type="hidden" value="" />
        <input id="amount" name="amount" type="hidden" value="" />
        <input id="orderID" name="orderID" type="hidden" value="" />
        <input id="cardBrand" name="cardBrand" type="hidden" value="" />
        <input id="customerEmail" name="customerEmail" type="hidden" value="" />
        <input id="customerPhone" name="customerPhone" type="hidden" value="" />
      </form>
      <div className={`${classesCpscc.mycanoncpscc} ${classesCpscc.top_level}`}>
        <div className={`${classesCpscc.mycanoncpscc} ${classesCpscc.custom_overlay}`}>
          <div className={`${classesCpscc.mycanoncpscc} ${classesCpscc.Popup__card}`}>
            <div className="">
              <form id="creditCard" className={`${classesCpscc.mycanoncpscc} ${classesCpscc.Form__Form}`}>
                <div id="info-field" className="">
                  <div style={margin20Style}>
                    <h2 className={`${classesCpscc.mycanoncpscc} ${classesCpscc.Label__main}`}>Card Information</h2>
                    <div className={`${classesCpscc.mycanoncpscc} ${classesCpscc.Label__group}`}></div>
                  </div>
                  <div id="cnumdiv">
                    <div className={`${classesCpscc.mycanoncpscc} ${classesCpscc.Row__Row} ${classesCpscc.Row__padding_sm}`}>
                      <div
                        className={`${classesCpscc.mycanoncpscc} ${classesCpscc.Col__Col} ${classesCpscc.Col__sm_12} ${classesCpscc.Col__md_8} ${classesCpscc.Col__padding_sm}`}
                      >
                        <div>
                          <div className={`${classesCpscc.mycanoncpscc} ${classesCpscc.Label__group}`}>
                            <div
                              className={`${classesCpscc.mycanoncpscc} ${classesCpscc.Input__Input} ${classesCpscc.Search__SearchInput} ${creditCardNumberBorderClass}`}
                            >
                              <div
                                id="creditCardNumber"
                                style={creditCardStyle}
                                tabIndex={1}
                                ref={creditCardNumberRef}
                                aria-disabled={creditCardNumberDisabled}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className={`${classesCpscc.mycanoncpscc} ${classesCpscc.Col__Col} ${classesCpscc.Col__sm_12} ${classesCpscc.Col__md_4} ${classesCpscc.Col__padding_sm}`}
                      >
                        <div className="" id="a">
                          <div className={`${classesCpscc.mycanoncpscc} ${classesCpscc.Label__group}`}>
                            <div className={`${classesCpscc.mycanoncpscc} ${classesCpscc.Search__SearchContainer}`}>
                              <div
                                className={`${classesCpscc.mycanoncpscc} ${classesCpscc.Input__Input} ${classesCpscc.Search__SearchInput} ${securityCodeBorderClass}`}
                              >
                                <div
                                  id="securityCode"
                                  style={creditCardStyle}
                                  tabIndex={2}
                                  ref={securityCodeRef}
                                  aria-disabled={securityCodeDisabled}
                                ></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <div
                        className={`${classesCpscc.mycanoncpscc} ${classesCpscc.Row__Row} ${classesCpscc.Row__padding_sm} ${classesCpscc.Row__Row_Left}`}
                      >
                        <div className={`${classesCpscc.mycanoncpscc} ${classesCpscc.CardButton__Group_sm}`}>
                          <div className={`${classesCpscc.mycanoncpscc} ${classesCpscc.CardButton__inner_sm}`}>
                            <div
                              className={`${classesCpscc.mycanoncpscc} ${classesCpscc.Link__Link} ${classesCpscc.CardButton__CardButton_sm}`}
                            >
                              <input
                                type="radio"
                                value="visa"
                                name="cardtype"
                                id="visa"
                                tabIndex={-1}
                                disabled={true}
                                checked={selectedCreditCard === "visa" || selectedCreditCard === "VI"}
                                onClick={() => {
                                  return false;
                                }}
                              />
                              <label htmlFor="visa"></label>
                              <img src={require("./img/vi.png")} alt="Visa" />
                            </div>
                            <div
                              className={`${classesCpscc.mycanoncpscc} ${classesCpscc.Link__Link} ${classesCpscc.CardButton__CardButton_sm}`}
                            >
                              <input
                                type="radio"
                                value="mastercard"
                                name="cardtype"
                                tabIndex={-1}
                                disabled={true}
                                checked={selectedCreditCard === "mastercard" || selectedCreditCard === "MC"}
                                id="mastercard"
                                onClick={() => {
                                  return false;
                                }}
                              />
                              <label htmlFor="mastercard"></label>
                              <img src={require("./img/mc.png")} alt="Mastercard" />
                            </div>
                            <div
                              className={`${classesCpscc.mycanoncpscc} ${classesCpscc.Link__Link} ${classesCpscc.CardButton__CardButton_sm}`}
                            >
                              <input
                                type="radio"
                                value="amex"
                                name="cardtype"
                                id="amex"
                                tabIndex={-1}
                                disabled={true}
                                checked={selectedCreditCard === "amex" || selectedCreditCard === "AX"}
                                onClick={() => {
                                  return false;
                                }}
                              />
                              <label htmlFor="amex"></label>
                              <img src={require("./img/ae.png")} alt="American Express" />
                            </div>
                            <div
                              className={`${classesCpscc.mycanoncpscc} ${classesCpscc.Link__Link} ${classesCpscc.CardButton__CardButton_sm}`}
                            >
                              <input
                                type="radio"
                                value="discover"
                                name="cardtype"
                                tabIndex={-1}
                                disabled={true}
                                id="discover"
                                checked={selectedCreditCard === "discover" || selectedCreditCard === "DI"}
                                onClick={() => {
                                  return false;
                                }}
                              />
                              <label htmlFor="discover"></label>
                              <img src={require("./img/di.png")} alt="Discover" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className={`${classesCpscc.mycanoncpscc} ${classesCpscc.Row__Row} ${classesCpscc.Row__padding_sm}`}>
                      <div
                        className={`${classesCpscc.mycanoncpscc} ${classesCpscc.Col__Col} ${classesCpscc.Col__sm_12} ${classesCpscc.Col__md_6} ${classesCpscc.Col__padding_sm}`}
                      >
                        <div className="" id="b">
                          <div className={`${classesCpscc.mycanoncpscc} ${classesCpscc.Label__group} ${classesCpscc.select__parent}`}>
                            <select
                              id="expirationMonth"
                              name="expirationMonth"
                              tabIndex={3}
                              value={expirationMonth}
                              disabled={expirationMonthDisabled}
                              ref={expirationMonthRef}
                              onChange={(event) => onChangeHandler("expirationMonth", event)}
                              onKeyDown={(event) => onKeyDownHandler("expirationMonth", event)}
                              onBlur={(event) => onBlurHandler("expirationMonth", event)}
                              className={`${classesCpscc.mycanoncpscc} ${classesCpscc.Input__Input}`}
                            >
                              <option value="01">01 - January</option>
                              <option value="02">02 - February</option>
                              <option value="03">03 - March</option>
                              <option value="04">04 - April</option>
                              <option value="05">05 - May</option>
                              <option value="06">06 - June</option>
                              <option value="07">07 - July</option>
                              <option value="08">08 - August</option>
                              <option value="09">09 - September</option>
                              <option value="10">10 - October</option>
                              <option value="11">11 - November</option>
                              <option value="12">12 - December</option>
                            </select>{" "}
                          </div>
                        </div>
                      </div>
                      <div
                        className={`${classesCpscc.mycanoncpscc} ${classesCpscc.Col__Col} ${classesCpscc.Col__sm_12} ${classesCpscc.Col__md_6} ${classesCpscc.Col__padding_sm}`}
                      >
                        <div className="" id="c">
                          <div className={`${classesCpscc.mycanoncpscc} ${classesCpscc.Label__group} ${classesCpscc.select__parent}`}>
                            <select
                              id="expirationYear"
                              name="expirationYear"
                              tabIndex={4}
                              value={expirationYear}
                              disabled={expirationYearDisabled}
                              ref={expirationYearRef}
                              onChange={(event) => onChangeHandler("expirationYear", event)}
                              onKeyDown={(event) => onKeyDownHandler("expirationYear", event)}
                              onBlur={(event) => onBlurHandler("expirationYear", event)}
                              className={`${classesCpscc.mycanoncpscc} ${classesCpscc.Input__Input}`}
                            ></select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div style={{ display: "flex", textAlign: "left", verticalAlign: "middle" }}>
                    <h1
                      className={`${classesCpscc.mycanoncpscc} ${classesCpscc.h1}`}
                      id="validationMessages"
                      tabIndex={-1}
                      style={{
                        color: "#D8000C",
                        display: "inline-flex" /* keep the background color wrapped tight */,
                        backgroundColor: componentObjRef.current.isBackgroundRed ? "#FFBABA" : "white",
                      }}
                    >
                      {validationMessages}
                    </h1>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
