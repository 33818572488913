import "./instlpaypoc.css";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";
import { InstlpayPocInterface } from "../../interface/redux-state/InstlpayPocInterface";
import { useParams } from "react-router-dom";
import React, { useCallback, useEffect, useRef, useState, KeyboardEvent } from "react";
import { isFirefox } from "react-device-detect";
import {
  resetStateAndError,
  changeStateError,
  setCvv,
  setCardType,
  loading,
  updateError,
  setProcessing,
  resetForNewCard,
  setInstallmentSubmitted,
  setBinInstallments,
} from "../../redux-store/reducer/instlpayPocSlice";
import { LogService, LOGINFOLEVEL } from "../../service/logService";
import { TceppsTestData, isTestingApp } from "../../utils/TestLoadData";
import PaymentService from "../../service/paymentService";
import { initializeCpgmui, resetCpgmui } from "../../redux-store/reducer/cpgmuiSlice";
import {
  extractClientLibrary,
  classNames,
  extractRequestParameters,
  getCardType,
  extractDomain,
  loadCybersourceJS,
} from "../../utils/utils";
import { Cpgmui, CpgmuiEvents } from "../../utils/Cpgmui";
import { contextLoadedAction, microformInitializedAction } from "../../redux-store/reducer/paymentSlice";
import axios, { AxiosError } from "axios";
import { createCybersourceToken } from "../../utils/cybersourceUtil";
import { getYearsFromToday, isCybersourceTimedout, getTimedoutDummyToken } from "../../utils/calendarUtil";

const errorFlags = {
  ccExpExpired: false,
  customerNameMissing: false,
  customerNameMinLength: false,
  customerFatherNameMissing: false,
  customerFatherMinLength: false,
  creditCardMissing: false,
  creditCardInvalid: false,
  cvvMissing: false,
  cvvInvalid: false,
  installmentMissing: false,
};
const APP_NAME = "INSTLPAYPOC";

const SERVICE_OPERATION = {
  profileAdd: "profileAdd",
};

type Microform = {
  microform: (options: any) => any;
};
const flexStyles = {
  input: {
    cursor: "text",
    color: "#171719",
    "font-size": "8pt",
    "font-family": '"Arial", sans-serif',
  },
  "::placeholder": { color: "lightslategrey" },
  ":focus": { color: "black" },
  ":disabled": { cursor: "not-allowed" },
  valid: { color: "#171719" },
  invalid: { color: "#a94442" },
} as React.CSSProperties;

var creditCardMicroNumber: any;
var cvvMicroSecurityCode: any;
const browserStartTime = new Date();
const currentMonth = browserStartTime.toLocaleString("en-US", { month: "2-digit" });
const currentYear = browserStartTime.toLocaleString("en-US", { year: "numeric" });

export function InstlpayPocComponent() {
  const instlpayPocState = useSelector((state: any) => state.instlpaypoc) as InstlpayPocInterface;
  const cpgmuiState = useSelector((state: any) => state.cpgmui);
  const paymentInfo = useSelector((state: any) => state.paymentInfo);
  const dispatch = useDispatch();
  const cpgmuiRef = useRef<Cpgmui>();

  const [micorFieldFocust, setMicorFieldFocust] = useState<boolean>(false);
  const [microFormLoadErrorMessage, setMicroFormLoadErrorMessage] = useState("");
  const [proxyUrl, setProxyUrl] = useState<string>("");

  const buttonClickRef = useRef<boolean>(false);
  const microFromFocusRef = useRef<string>("");
  const setupCcExpYearRef = useRef<boolean>(false);
  const setupTestDataRef = useRef<boolean>(false);

  const userCardsRef = useRef<string>("");
  const ccExpMonthRef = useRef<HTMLSelectElement>(null);
  const ccExpYearRef = useRef<HTMLSelectElement>(null);
  const customerMotherNameRef = useRef<HTMLInputElement>(null);
  const customerNameRef = useRef<HTMLInputElement>(null);
  const installmentRef = useRef<HTMLSelectElement>(null);

  const { appKey } = useParams();
  //* initialize iframe -----------------------------
  useEffect(() => {
    const processRequest = async (req: any) => {
      const resp = await PaymentService.getDecryptRequest(req.app, req.encryptedRequestKey, req.encryptedRequest);
      req.unencryptedRequest = resp.data;
      console.log("req.unencryptedRequest");
      console.log(req.unencryptedRequest);
      cpgmuiRef.current = new Cpgmui(req.unencryptedRequest["tcepps.proxyUrl"]);
      setProxyUrl(req.unencryptedRequest["tcepps.proxyUrl"]);
      dispatch(initializeCpgmui(req));
      if (!setupCcExpYearRef.current) {
        try {
          getYearsFromToday(11, document.getElementById("ccExpYear") as HTMLSelectElement, false);
          setupCcExpYearRef.current = true;
        } catch (error) {}
      }
      return () => {
        dispatch(resetCpgmui());
        dispatch(resetStateAndError());
      };
    };

    const requestParameters = extractRequestParameters(appKey || "");
    processRequest(requestParameters).then(() => {
      cpgmuiRef.current?.iframeInitialized();
    });
  }, [dispatch, appKey]);

  const getCardBin = useCallback(async () => {
    try {
      let resp;
      let tokenInfo;
      if (isCybersourceTimedout(browserStartTime)) {
        const { jti, lastFourDigits } = getTimedoutDummyToken();
        tokenInfo = { jti: jti, lastFourDigits: lastFourDigits, bin: "123456" };
      } else {
        const expirationMonth = instlpayPocState.ccExpMonth;
        const expirationYear = (parseFloat(currentYear) + 1).toFixed(0).toLocaleString(); //instlpayPocState.ccExpYear;
        resp = await createCybersourceToken(expirationMonth, expirationYear, paymentInfo.microform);
        tokenInfo = resp as { jti: string; lastFourDigits: string; bin: string };
      }
      // post message to get valid installment periods for the bin
      cpgmuiRef.current?.getInstallments(tokenInfo.bin);
      dispatch(setInstallmentSubmitted({ installmentSubmitted: true }));
    } catch (err) {
      console.log(err);
    }
  }, [dispatch, instlpayPocState.ccExpMonth, paymentInfo.microform]);

  // when the card has changed and is valid get valid installments
  useEffect(() => {
    if (
      instlpayPocState.cardValid &&
      instlpayPocState.cardChanged &&
      instlpayPocState.cvvValid &&
      !instlpayPocState.error.ccExpExpired &&
      !instlpayPocState.installmentSubmitted &&
      !micorFieldFocust
    ) {
      getCardBin();
    }
  }, [
    dispatch,
    instlpayPocState.cardValid,
    instlpayPocState.cardChanged,
    instlpayPocState.installmentSubmitted,
    instlpayPocState.cvvValid,
    instlpayPocState.error.ccExpExpired,
    micorFieldFocust,
    getCardBin,
  ]);

  //* message handler --------------------------------
  const validateForm = useCallback(() => {
    let temp = errorFlags;
    let hasError = false;
    if (!instlpayPocState.customerName || instlpayPocState.customerName.trim().length === 0) {
      temp = { ...temp, customerNameMissing: true };
      hasError = true;
    } else if (instlpayPocState.customerName.trim().length < 2) {
      temp = { ...temp, customerNameMinLength: true };
      hasError = true;
    }
    if (!instlpayPocState.customerFatherName) {
      temp = { ...temp, customerFatherNameMissing: true };
      hasError = true;
    } else if (instlpayPocState.customerFatherName.trim().length < 2) {
      temp = { ...temp, customerFatherMinLength: true };
      hasError = true;
    }
    if (!instlpayPocState.cardBrand) {
      temp = { ...temp, creditCardInvalid: true };
      hasError = true;
    } else if (!instlpayPocState.cardValid) {
      temp = { ...temp, creditCardMissing: true };
      hasError = true;
    }
    if (!instlpayPocState.cvvValid) {
      temp = { ...temp, cvvMissing: true, cvvInvalid: true };
      hasError = true;
    } else if (!instlpayPocState.cvvEmpty) {
      temp = { ...temp, cvvMissing: true };
      hasError = true;
    }

    const expirationMonth = instlpayPocState.ccExpMonth;
    const expirationYear = instlpayPocState.ccExpYear;

    if (
      !expirationMonth ||
      !expirationYear ||
      currentYear > expirationYear ||
      (currentYear === expirationYear && currentMonth > expirationMonth)
    ) {
      temp = { ...temp, ccExpExpired: true };
      hasError = true;
    }

    // check if user has selected installments
    if (!hasError) {
      if (instlpayPocState.cardValid && !instlpayPocState.installmentSubmitted) {
        temp = { ...temp, installmentMissing: true };
        hasError = true;
      } else if (instlpayPocState.cardValid && instlpayPocState.installmentSubmitted && instlpayPocState.installment === "") {
        temp = { ...temp, installmentMissing: true };
        hasError = true;
      }
    }

    if (hasError) {
      dispatch(updateError(temp));
    }
    return {
      hasError: hasError,
      errors: temp,
    };
  }, [dispatch, instlpayPocState]);

  const buildCardTable = useCallback(
    async (jti: string, lastFourDigits: string, bin: string) => {
      dispatch(setProcessing(true));
      const changedState: { [index: string]: string | any } = { ...instlpayPocState };

      const newCardDetails = {
        cardBrand: instlpayPocState.cardBrand,
        ccAccountNum: jti,
        lastFourDigits: lastFourDigits,
        bin: bin,
        ccExp:
          (instlpayPocState.ccExpMonth.toString().length < 2 ? "0" + instlpayPocState.ccExpMonth : instlpayPocState.ccExpMonth) +
          "/" +
          instlpayPocState.ccExpYear,
        customerName: instlpayPocState.customerName,
        customerFatherName: instlpayPocState.customerFatherName,
        customerMotherName: instlpayPocState.customerMotherName,
        installments: instlpayPocState.installment,
      };
      changedState["userCards"] = newCardDetails;
      userCardsRef.current = JSON.stringify(newCardDetails);
      dispatch(changeStateError(changedState));
      dispatch(setProcessing(false));

      // encrypt data
      const resp = await PaymentService.getEncryptResponse(APP_NAME, JSON.stringify(newCardDetails));
      await LogService.logMessage(
        "INSTPAYPOC firstName#" + customerNameRef.current?.value,
        LOGINFOLEVEL,
        "resp.data.encryptedResponse:" +
          (resp.data && resp.data.encryptedResponse ? resp.data.encryptedResponse : "") +
          " resp.data.encryptedResponseKey:" +
          (resp.data && resp.data.encryptedResponseKey ? resp.data.encryptedResponseKey : "")
      );

      return resp.data;
    },
    [instlpayPocState, dispatch]
  );

  const clearFieldsAndState = useCallback(
    (clearDate: boolean) => {
      const changedState: { [index: string]: string | any } = { ...instlpayPocState };
      changedState["userCards"] = userCardsRef.current;
      if (clearDate) {
        const ccExpMonth: HTMLSelectElement = document.getElementById("ccExpMonth") as HTMLSelectElement;
        ccExpMonth.options[ccExpMonth.length] = new Option("  ", "  ");
        changedState["ccExpMonth"] = "  ";
        const ccExpYear: HTMLSelectElement = document.getElementById("ccExpYear") as HTMLSelectElement;
        ccExpYear.options[ccExpYear.length] = new Option("    ", "    ");
        changedState["ccExpYear"] = "    ";
        changedState["formSubmitted"] = true;
      } else {
        changedState["ccExpMonth"] = currentMonth;
        changedState["ccExpYear"] = currentYear;
        changedState["formSubmitted"] = false;
      }
      dispatch(resetForNewCard(changedState));
      creditCardMicroNumber.clear();
      cvvMicroSecurityCode.clear();
      buttonClickRef.current = false;
    },
    [dispatch, instlpayPocState]
  );

  const submitProfileAdd = useCallback(async () => {
    try {
      let resp;
      let tokenInfo;
      if (isCybersourceTimedout(browserStartTime)) {
        const { jti, lastFourDigits } = getTimedoutDummyToken();
        tokenInfo = { jti: jti, lastFourDigits: lastFourDigits, bin: "123456" };
      } else {
        const expirationMonth = instlpayPocState.ccExpMonth;
        const expirationYear = instlpayPocState.ccExpYear;
        resp = await createCybersourceToken(expirationMonth, expirationYear, paymentInfo.microform);
        tokenInfo = resp as { jti: string; lastFourDigits: string; bin: string };
      }
      const profileAddRequest = await buildCardTable(tokenInfo.jti, tokenInfo.lastFourDigits, tokenInfo.bin);

      clearFieldsAndState(true);

      cpgmuiRef.current?.response(profileAddRequest);
    } catch (err) {
      console.log(err);
    }
  }, [buildCardTable, instlpayPocState.ccExpMonth, instlpayPocState.ccExpYear, paymentInfo.microform, clearFieldsAndState]);

  const windowMessageHandler = useCallback(
    (event: MessageEvent) => {
      if (
        event.data &&
        (event.data as any).data !== undefined &&
        (event.data as any).data &&
        ((event.data as any).data as any).focusShift !== undefined &&
        ((event.data as any).data as any).focusShift &&
        (event.data as any).event !== undefined &&
        (event.data as any).event === "blur" &&
        event.currentTarget &&
        (event.currentTarget as any).name !== undefined &&
        (event.currentTarget as any).name &&
        (event.currentTarget as any).name === "creditCardFormIFrame"
      ) {
        if (((event.data as any).data as any).focusShift === "next") {
          if (microFromFocusRef.current === "creditCardNumber") {
            microFromFocusRef.current = "";
            ccExpMonthRef.current?.focus();
          } else if (microFromFocusRef.current === "securityCode") {
            microFromFocusRef.current = "";
            installmentRef.current?.focus();
          }
        } else if (((event.data as any).data as any).focusShift === "previous") {
          if (microFromFocusRef.current === "creditCardNumber") {
            microFromFocusRef.current = "";
            customerMotherNameRef.current?.focus();
          } else if (microFromFocusRef.current === "securityCode") {
            microFromFocusRef.current = "";
            ccExpYearRef.current?.focus();
          }
        }
      } else if (
        (event.origin &&
          event.origin.startsWith("https://") &&
          (event.origin.endsWith(".usa.canon.com") ||
            event.origin.indexOf(".usa.canon.com/") > 0 ||
            event.origin.endsWith(".cusa.canon.com") ||
            event.origin.indexOf(".cusa.canon.com/") > 0)) ||
        (event.origin && event.origin.startsWith("http://") && event.origin.indexOf("localhost") > 0)
      ) {
        if (event.data.type === CpgmuiEvents.execute) {
          const serviceOperation = event.data.payload.serviceOperation;

          if (serviceOperation === SERVICE_OPERATION.profileAdd) {
            buttonClickRef.current = true;
            const validationResult = validateForm();
            if (validationResult.hasError) {
              cpgmuiRef.current?.validated(validationResult);
            } else {
              if (serviceOperation === SERVICE_OPERATION.profileAdd) {
                submitProfileAdd();
              }
            }
          }
        } else if (event.data.type === CpgmuiEvents.installments) {
          let binInstallments = " ";
          if (event.data && event.data.payload && event.data.payload.binInstallments) {
            binInstallments = event.data.payload.binInstallments;
            if (!binInstallments) {
              binInstallments = " ";
            }
            LogService.logMessage(
              "INSTPAYPOC firstName#" + customerNameRef.current?.value,
              LOGINFOLEVEL,
              "binInstallments:" + binInstallments
            );
          }
          dispatch(setBinInstallments({ binInstallments: binInstallments }));
        } else if (event.data.type === CpgmuiEvents.validate) {
          const validationResult = validateForm();
          cpgmuiRef.current?.validated(validationResult);
        } else if (event.data.type === CpgmuiEvents.custom) {
          const customInfo = event.data.payload;
          if (customInfo.action === "testError") {
            const errorList = ["Something really bad happened.", "More bad news."];
            cpgmuiRef.current?.error(errorList);
          }
        }
      }
    },
    [dispatch, validateForm, submitProfileAdd]
  );

  useEffect(() => {
    if (cpgmuiState.unencryptedRequest && !setupTestDataRef.current) {
      if (isTestingApp(cpgmuiState.unencryptedRequest["app.TESTINGAPP"])) {
        const changedState: { [index: string]: string | any } = { ...instlpayPocState };
        changedState["ccExpMonth"] = TceppsTestData.ToBeAppTestData.INSTLPAYPOC["ppa.test.expirationMonth"];
        changedState["ccExpYear"] = TceppsTestData.ToBeAppTestData.INSTLPAYPOC["ppa.test.expirationYear"];
        dispatch(changeStateError(changedState));
      } else {
        const changedState: { [index: string]: string | any } = { ...instlpayPocState };
        changedState["ccExpMonth"] = ccExpMonthRef.current?.value;
        changedState["ccExpYear"] = ccExpYearRef.current?.value;
        dispatch(changeStateError(changedState));
      }
      setupTestDataRef.current = true;
    }
  }, [dispatch, instlpayPocState, cpgmuiState.unencryptedRequest]);

  useEffect(() => {
    // setCollapsed(true);
    window.addEventListener("message", windowMessageHandler, false);

    return () => {
      window.removeEventListener("message", windowMessageHandler);
    };
  }, [windowMessageHandler]);

  //* setup microform...
  useEffect(() => {
    const updateCardBrand = (cardName: string, isValid: boolean, isEmpty: boolean) => {
      const cardBrand = getCardType(cardName);
      let creditCardInvalid = true;
      if (cardBrand && cardBrand.length > 1) {
        creditCardInvalid = false;
      }
      dispatch(
        setCardType({
          cardBrand: cardBrand || "",
          cardValid: isValid && !isEmpty ? true : false,
          creditCardInvalid: creditCardInvalid,
          creditCardMissing: isEmpty,
          cardChanged: true, //to document state
          installmentSubmitted: false, //to document state
          binInstallments: " ", //to document state
        })
      );
    };

    const initializeMicroform = async (captureContext: any) => {
      const flex = (await new (window as any).Flex(captureContext)) as Microform;
      const microform = await flex.microform({ styles: flexStyles });
      creditCardMicroNumber = await microform.createField("number", { placeholder: "Card Number " });
      cvvMicroSecurityCode = await microform.createField("securityCode", { placeholder: "CVV *" });
      await creditCardMicroNumber.load("#creditCardNumber");
      await cvvMicroSecurityCode.load("#securityCode");
      dispatch(microformInitializedAction(microform));

      if (creditCardMicroNumber) {
        creditCardMicroNumber.on("change", (data: any) => {
          if (data.card && data.card.length > 0) {
            updateCardBrand(data.card[0].name, data.valid, data.empty);
          } else {
            updateCardBrand("", data.valid, data.empty);
          }
        });
        creditCardMicroNumber.on("focus", function (data: any) {
          setMicorFieldFocust(true);
          microFromFocusRef.current = "creditCardNumber";
          (document.querySelector("#creditCardNumber") as HTMLDivElement).style.outline = "5px auto -webkit-focus-ring-color";
          if (isFirefox) {
            (document.querySelector("#creditCardNumber") as HTMLDivElement).style.outline = "1px solid deepskyblue";
            (document.querySelector("#creditCardNumber") as HTMLDivElement).style.outlineOffset = "2px";
            (document.querySelector("#creditCardNumber") as HTMLDivElement).style.border = "1.5px solid blue";
          }
        });
        creditCardMicroNumber.on("blur", function (data: any) {
          setMicorFieldFocust(false);
          (document.querySelector("#creditCardNumber") as HTMLDivElement).style.outline = "none";
          (document.querySelector("#creditCardNumber") as HTMLDivElement).style.border = "1px solid black";
          if (isFirefox) {
            (document.querySelector("#creditCardNumber") as HTMLDivElement).style.outlineOffset = "0px";
            (document.querySelector("#creditCardNumber") as HTMLDivElement).style.border = "1px solid #858585";
          }
        });
        creditCardMicroNumber.on("load", function (data: any) {
          dispatch(loading(false));
          customerNameRef.current?.focus();
        });
      }

      if (cvvMicroSecurityCode) {
        cvvMicroSecurityCode.on("change", function (data: any) {
          dispatch(setCvv({ cvvValid: !data.empty && data.valid, cvvEmpty: !data.empty }));
        });
        cvvMicroSecurityCode.on("focus", function (data: any) {
          setMicorFieldFocust(true);
          microFromFocusRef.current = "securityCode";
          (document.querySelector("#securityCode") as HTMLDivElement).style.outline = "5px auto -webkit-focus-ring-color";
          if (isFirefox) {
            (document.querySelector("#securityCode") as HTMLDivElement).style.outline = "1px solid deepskyblue";
            (document.querySelector("#securityCode") as HTMLDivElement).style.outlineOffset = "2px";
            (document.querySelector("#securityCode") as HTMLDivElement).style.border = "1.5px solid blue";
          }
        });
        cvvMicroSecurityCode.on("blur", function (data: any) {
          setMicorFieldFocust(false);
          (document.querySelector("#securityCode") as HTMLDivElement).style.outline = "none";
          (document.querySelector("#securityCode") as HTMLDivElement).style.border = "1px solid black";
          if (isFirefox) {
            (document.querySelector("#securityCode") as HTMLDivElement).style.outlineOffset = "0px";
            (document.querySelector("#securityCode") as HTMLDivElement).style.border = "1px solid #858585";
          }
        });
      }
    };

    const getCaptureContext = () => {
      // const iframeUrl = getIframeUrl();
      const iframeUrl = extractDomain(proxyUrl);
      // console.log("iframeUrl:" + iframeUrl);
      PaymentService.getCaptureContext(iframeUrl)
        .then((resp) => {
          // const captureContext = resp.data.data;
          const captureContext = resp.data.captureContext;
          const { clientLibrary, clientLibraryIntegrity } = extractClientLibrary(resp.data.json);
          dispatch(contextLoadedAction(captureContext));
          if (!(window as any).Flex) {
            loadCybersourceJS(captureContext, clientLibrary, clientLibraryIntegrity, initializeMicroform);
          } else {
            initializeMicroform(captureContext);
          }
        })
        .catch((err: Error | AxiosError) => {
          if (axios.isAxiosError(err)) {
            setMicroFormLoadErrorMessage("AxiosError: call to initialize microForm failed. " + err.message + ". Please check console.");
          } else {
            setMicroFormLoadErrorMessage("Initialize microForm failed. " + err.message + ". Please check console.");
          }
        });
    };

    if (proxyUrl && proxyUrl.length > 0) {
      getCaptureContext();
    }
  }, [dispatch, proxyUrl]);

  const handleChange = (event: any) => {
    const field = event.target.name as string;
    const changedState: { [index: string]: string | any } = { ...instlpayPocState };
    changedState[field] = (event.target.value as string).toUpperCase();
    dispatch(changeStateError(changedState));
    if (field !== "ccExpMonth" && field !== "ccExpYear") {
      const errorState: { [index: string]: boolean } = { ...instlpayPocState.error };
      errorState[field + "Missing"] = event.target.value && event.target.value.length > 0 ? false : true;
      dispatch(updateError(errorState));
    }
  };

  const onKeyDownHandler = (identifier: string, event: KeyboardEvent<HTMLSelectElement | HTMLInputElement | HTMLDivElement>) => {
    if (event.key === "Tab") {
      if (identifier === "ccExpMonth" && event.shiftKey) {
        if (creditCardMicroNumber) {
          creditCardMicroNumber.focus();
        }
      } else if (identifier === "customerMotherName" && !event.shiftKey) {
        if (creditCardMicroNumber) {
          creditCardMicroNumber.focus();
        }
      } else if (identifier === "ccExpYear" && !event.shiftKey) {
        if (cvvMicroSecurityCode) {
          cvvMicroSecurityCode.focus();
        }
      } else if (identifier === "installment" && event.shiftKey) {
        if (cvvMicroSecurityCode) {
          cvvMicroSecurityCode.focus();
        }
      } else if (identifier === "installment" && !event.shiftKey) {
        customerNameRef.current?.focus();
      }
    }
  };

  const getDateErrorMessage = (): string => {
    return "Date is expired. Please enter valid date.";
  };

  const getCardErrorMessage = (message: string): string => {
    if (microFormLoadErrorMessage) {
      return microFormLoadErrorMessage;
    } else {
      return message;
    }
  };

  const creditCardStyle = {
    fontSize: "8px",
    fontFamily: "'Arial',sans-serif",
    paddingLeft: "2px",
    // paddingRight: "-20px",
    height: "20px", //"20px"
    color: "fieldtext",
    letterSpacing: "normal",
    wordSpacing: "normal",
    lineHeight: "normal",
    textIndent: "0px",
    textShadow: "none",
    display: "inline-block",
    cursor: "text",
    width: "155px", //"163px"
    margin: "0em",
    borderWidth: "1px",
    borderStyle: "inset",
    borderColor: "rgb(133, 133, 133)",
    borderImage: "initial",
  } as React.CSSProperties;

  const securityStyle = {
    fontSize: "8px",
    fontFamily: "'Arial',sans-serif",
    paddingLeft: "2px",
    // paddingRight: "-20px",
    height: "20px", //"20px"
    color: "fieldtext",
    letterSpacing: "normal",
    wordSpacing: "normal",
    lineHeight: "normal",
    textIndent: "0px",
    textShadow: "none",
    display: "inline-block",
    cursor: "text",
    width: "40px",
    margin: "0em",
    borderWidth: "1.5px",
    borderStyle: "inset",
    borderColor: "rgb(133, 133, 133)",
    borderImage: "initial",
  } as React.CSSProperties;

  const tooltipStyle = {
    fontSize: "10pt",
    backgroundColor: "#ff6060 !important",
  };

  return (
    <>
      <div
        aria-disabled={instlpayPocState.loading}
        className={instlpayPocState.loading ? "instlpaypoc-grid-container instlpaypocDisabled" : "instlpaypoc-grid-container"}
      >
        <div className="instlpaypoc-grid-container">
          <div className="item1">*Cardholder Name</div>
          <div className="item2 instlpaypoc-span2">*Father's Surname</div>
          <div className="item3 instlpaypoc-span2">Mother's Surname</div>
          <div className="item4"></div>

          <div className="item1">
            <input
              name="customerName"
              id="customerName"
              type="text"
              style={{ width: "150px" }}
              value={instlpayPocState.customerName}
              disabled={instlpayPocState.processing}
              size={33}
              maxLength={30}
              tabIndex={1}
              ref={customerNameRef}
              onChange={handleChange}
              className={classNames({
                instlpaypocFieldError: instlpayPocState.error.customerNameMissing || instlpayPocState.error.customerNameMinLength,
                fieldOK: !(instlpayPocState.error.customerNameMissing && instlpayPocState.error.customerNameMinLength),
              })}
            />
            <Tooltip
              anchorSelect="#customerName"
              style={tooltipStyle}
              place="bottom"
              variant="error"
              hidden={!instlpayPocState.error.customerNameMissing}
              content="Please enter card holder name."
            />
            <Tooltip
              anchorSelect="#customerName"
              style={tooltipStyle}
              place="bottom"
              variant="error"
              hidden={!instlpayPocState.error.customerNameMinLength}
              content="Name should have mininum of 2 characters."
            />
          </div>
          <div className="item2 instlpaypoc-span2">
            <input
              name="customerFatherName"
              id="customerFatherName"
              type="text"
              style={{ width: "150px" }}
              value={instlpayPocState.customerFatherName}
              disabled={instlpayPocState.processing}
              size={33}
              maxLength={30}
              tabIndex={2}
              onChange={handleChange}
              className={classNames({
                instlpaypocFieldError: instlpayPocState.error.customerFatherNameMissing || instlpayPocState.error.customerFatherMinLength,
                fieldOK: !(instlpayPocState.error.customerFatherNameMissing && instlpayPocState.error.customerFatherMinLength),
              })}
            />
            <Tooltip
              anchorSelect="#customerFatherName"
              style={tooltipStyle}
              place="bottom"
              variant="error"
              hidden={!instlpayPocState.error.customerFatherNameMissing && !instlpayPocState.error.customerFatherMinLength}
              content="Please enter Father's Surname."
            />
            <Tooltip
              anchorSelect="#customerFatherName"
              style={tooltipStyle}
              place="bottom"
              variant="error"
              hidden={!instlpayPocState.error.customerFatherMinLength}
              content="Name should have mininum of 2 characters."
            />
          </div>
          <div className="item6 instlpaypoc-span2">
            <input
              name="customerMotherName"
              id="customerMotherName"
              type="text"
              style={{ width: "150px" }}
              value={instlpayPocState.customerMotherName}
              disabled={instlpayPocState.processing}
              size={33}
              maxLength={30}
              tabIndex={3}
              onChange={handleChange}
              onKeyDown={(event) => onKeyDownHandler("customerMotherName", event)}
              ref={customerMotherNameRef}
              className={classNames({
                instlpaypocFieldError: false,
                fieldOK: true,
              })}
            />
          </div>
          <div className="item4"></div>

          <div className="item1 instlpaypoc-span2">*Credit or Debit Card Number</div>
          <div className="item2 instlpaypoc-span2">*Expires (MM/YYYY)</div>
          <div className="item3">*Code(CVV)</div>
          <div className="item4"></div>

          <div className="item1 instlpaypoc-span2">
            <span>
              {instlpayPocState.cardBrand === "visa" ||
                (instlpayPocState.cardBrand === "VI" && <img className="instlpaypoc-image" src={require("./img/vi.png")} alt="Visa" />)}
              {instlpayPocState.cardBrand === "mastercard" ||
                (instlpayPocState.cardBrand === "MC" && (
                  <img className="instlpaypoc-image" src={require("./img/mc.png")} alt="Mastercard" />
                ))}
              {instlpayPocState.cardBrand === "amex" ||
                (instlpayPocState.cardBrand === "AX" && (
                  <img className="instlpaypoc-image" src={require("./img/ae.png")} alt="American Express" />
                ))}
              {instlpayPocState.cardBrand === "discover" ||
                (instlpayPocState.cardBrand === "DI" && <img className="instlpaypoc-image" src={require("./img/di.png")} alt="Visa" />)}
              {instlpayPocState.cardBrand === "" && <img className="instlpaypoc-image" src={require("./img/none.png")} alt=" " />}
            </span>
            <div
              id="creditCardNumber"
              style={creditCardStyle}
              // tabIndex={4}
              className={classNames({
                instlpaypocFieldError:
                  buttonClickRef.current && (instlpayPocState.error.creditCardInvalid || instlpayPocState.error.creditCardMissing),
                fieldOK: !(buttonClickRef.current || instlpayPocState.error.creditCardInvalid || instlpayPocState.error.creditCardMissing),
                instlpaypocTextInput: true,
              })}
            ></div>
            <Tooltip
              anchorSelect="#creditCardNumber"
              style={tooltipStyle}
              place="right-start"
              variant="error"
              hidden={!buttonClickRef.current || !instlpayPocState.error.creditCardMissing}
              content={getCardErrorMessage("Please enter a credit card number.")}
            />
            <Tooltip
              anchorSelect="#creditCardNumber"
              style={tooltipStyle}
              place="right-start"
              variant="error"
              hidden={!buttonClickRef.current || !instlpayPocState.error.creditCardInvalid}
              content={getCardErrorMessage("Please enter a visa or a mastercard or amex or discover card number.")}
            />
          </div>
          <div className="item2 instlpaypoc-span2">
            <select
              tabIndex={5}
              id="ccExpMonth"
              name="ccExpMonth"
              value={instlpayPocState.ccExpMonth}
              onChange={handleChange}
              size={1}
              ref={ccExpMonthRef}
              disabled={instlpayPocState.processing}
              onKeyDown={(event) => onKeyDownHandler("ccExpMonth", event)}
              className={classNames({
                instlpaypocFieldError: instlpayPocState.error.ccExpExpired,
                fieldOK: !instlpayPocState.error.ccExpExpired,
              })}
            >
              <option value="01">01</option>
              <option value="02">02</option>
              <option value="03">03</option>
              <option value="04">04</option>
              <option value="05">05</option>
              <option value="06">06</option>
              <option value="07">07</option>
              <option value="08">08</option>
              <option value="09">09</option>
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
            </select>
            <Tooltip
              anchorSelect="#ccExpMonth"
              style={tooltipStyle}
              place="bottom"
              variant="error"
              hidden={!instlpayPocState.error.ccExpExpired}
              content={getDateErrorMessage()}
            />
            <select
              tabIndex={6}
              id="ccExpYear"
              name="ccExpYear"
              value={instlpayPocState.ccExpYear}
              onChange={handleChange}
              style={{ width: "60px" }}
              size={1}
              onKeyDown={(event) => onKeyDownHandler("ccExpYear", event)}
              ref={ccExpYearRef}
              disabled={instlpayPocState.processing}
              className={classNames({
                instlpaypocFieldError: instlpayPocState.error.ccExpExpired,
                fieldOK: !instlpayPocState.error.ccExpExpired,
                instlpaypocMarginLeft: true,
              })}
            ></select>
            <Tooltip
              anchorSelect="#ccExpYear"
              style={tooltipStyle}
              place="bottom"
              variant="error"
              hidden={!instlpayPocState.error.ccExpExpired}
              content={getDateErrorMessage()}
            />
          </div>
          <div className="item3">
            <div
              id="securityCode"
              style={securityStyle}
              // tabIndex={7}
              className={classNames({
                instlpaypocFieldError: buttonClickRef.current && (instlpayPocState.error.cvvInvalid || instlpayPocState.error.cvvMissing),
                fieldOK: !(buttonClickRef.current || instlpayPocState.error.cvvInvalid || instlpayPocState.error.cvvMissing),
              })}
            ></div>
            <Tooltip
              anchorSelect="#securityCode"
              style={tooltipStyle}
              place="right-start"
              variant="error"
              hidden={!buttonClickRef.current || !instlpayPocState.error.cvvMissing}
              content="Please enter a Code(CVV)."
            />
            <Tooltip
              anchorSelect="#securityCode"
              style={tooltipStyle}
              place="right-start"
              variant="error"
              hidden={!buttonClickRef.current || !instlpayPocState.error.cvvInvalid}
              content="Please enter a valid Code(CVV)."
            />
          </div>
          <div className="item4"></div>

          <div
            className="item1 instlpaypoc-span3by2 instlpaypocMarginLeft20"
            // style={{ display: instlpayPocState.binInstallments !== " " ? "" : "none" }}
          >
            Check that your bank and credit card participate in Interest-Free Months:
          </div>
          <div>
            <label
              htmlFor="installmentCount"
              // hidden={instlpayPocState.processing}
              className={instlpayPocState.binInstallments === " " ? "instlpaypocDisabled" : ""}
            >
              Installments:
            </label>
          </div>
          <div className="item1 instlpaypoc-span2">
            <select
              tabIndex={8}
              id="installment"
              name="installment"
              value={instlpayPocState.installment}
              onChange={handleChange}
              size={1}
              disabled={instlpayPocState.processing}
              ref={installmentRef}
              // hidden={instlpayPocState.processing}
              onKeyDown={(event) => onKeyDownHandler("installment", event)}
              className={classNames({
                instlpaypocFieldError: instlpayPocState.error.installmentMissing,
                fieldOK: !instlpayPocState.error.installmentMissing,
                instlpaypocMarginLeft5: true,
              })}
            >
              <option defaultValue=" " hidden>
                Please Choose
              </option>
              {instlpayPocState.binInstallments.indexOf("0") >= 0 && <option value="0">No Installments</option>}
              {instlpayPocState.binInstallments.indexOf("3") >= 0 && <option value="3">3 Installments</option>}
              {instlpayPocState.binInstallments.indexOf("6") >= 0 && <option value="6">6 Installments</option>}
              {instlpayPocState.binInstallments.indexOf("9") >= 0 && <option value="9">9 Installments</option>}
              {instlpayPocState.binInstallments.indexOf("12") >= 0 && <option value="12">12 Installments</option>}
              {instlpayPocState.binInstallments.indexOf("18") >= 0 && <option value="18">18 Installments</option>}
            </select>
            <Tooltip
              anchorSelect="#installmentCount"
              style={tooltipStyle}
              place="bottom"
              variant="error"
              hidden={!instlpayPocState.error.installmentMissing}
              content={"Please select an installment"}
            />
          </div>

          <input id="cardBrand" name="cardBrand" type="hidden" value="" tabIndex={9} />
          <div className="item1 instlpaypoc-span5">
            <span style={{ color: "red", backgroundColor: "#dfe6df !important" }}>{microFormLoadErrorMessage}</span>
          </div>
        </div>
      </div>
    </>
  );
}
