import { Buffer } from "buffer";

const INVALIDCARDMESSAGE = "Please enter a valid credit card number.";
const INVALIDCVVMESSAGE = "Please enter a valid CVV Number.";

const getIframeUrl = () => {
  //const iframeUrl = document.referrer + "/";
  //const iframeUrl = document.location + '/';
  // return (iframeUrl.indexOf("/", 7) > 0) ? iframeUrl.substring(0, iframeUrl.indexOf("/", 7) + 1) : iframeUrl;
  const iframeUrl = document.referrer;
  return iframeUrl;
};

const extractDomain = (url: string) => {
  if (url) {
    if (!url.endsWith("/")) {
      url = url + "/";
    }
    let idxColon = url.indexOf(":") + 4;
    url = url.substring(0, url.indexOf("/", idxColon) + 1);
    // for microform V2
    if (url.endsWith("/") && url.length > 2) {
      url = url.slice(0, -1);
    }
  }
  return url;
};

const extractClientLibrary = (contextJson: any) => {
  let clientLibrary = "";
  let clientLibraryIntegrity = "";

  if (contextJson.ctx && Array.isArray(contextJson.ctx) && contextJson.ctx[0] && contextJson.ctx[0].data) {
    const data = contextJson.ctx[0].data;
    clientLibrary = data.clientLibrary;
    clientLibraryIntegrity = data.clientLibraryIntegrity;
  }
  return { clientLibrary: clientLibrary, clientLibraryIntegrity: clientLibraryIntegrity };
};

const loadCybersourceJS = (captureContext: any, clientLibrary: string, clientLibraryIntegrity: string, callback: (cc: any) => void) => {
  const script = document.createElement("script");
  script.type = "text/javascript";
  // script.src = config.cybersource.microformJs;
  script.src = clientLibrary;
  script.integrity = clientLibraryIntegrity;
  script.crossOrigin = "anonymous";
  document.body.appendChild(script);
  script.onload = () => callback(captureContext);
};

const cardTypes = new Map<string, string>();
cardTypes.set("VI", "VI");
cardTypes.set("visa", "VI");
cardTypes.set("mastercard", "MC");
cardTypes.set("discover", "DI");
cardTypes.set("AX", "AX");
cardTypes.set("amex", "AX");

const getCardType = (cardName: string) => {
  return cardTypes.get(cardName);
};

const classNames = (classes: { [index: string]: boolean }) => {
  const keys = Object.keys(classes);
  const cNames = keys.reduce((acc: string, k: string) => {
    const space = acc.length > 0 ? " " : "";
    const cls = classes[k] ? k : "";
    return acc + space + cls;
  }, "");
  return cNames;
};

const extractRequestParameters = (data: string) => {
  const temp = Buffer.from(data ? data : "", "base64");
  const temp1 = temp.toString("utf8");
  const json = JSON.parse(temp1);
  const keys = Object.keys(json);
  const requestParameters: { [index: string]: string } = {};
  keys.forEach((k) => {
    const strs = k.split(".");
    if (strs.length === 2) {
      requestParameters[strs[1]] = json[k];
    } else if (strs.length === 1) {
      requestParameters[strs[0]] = json[k];
    }
  });
  adjustRequestParameters(requestParameters);
  return requestParameters;
};

const adjustRequestParameters = (requstParameters: any) => {
  const cpgmuiApiUrl = requstParameters.cpgmuiApiUrl;

  if (cpgmuiApiUrl) {
    requstParameters.cpgmuiApiUrl = cpgmuiApiUrl + "/TCEPPS/jsp/tceppsHtmlFormInterface.jsp?tceppsApp=" + requstParameters.app;
  }

  const cpgmuiEnv = requstParameters.cpgmuiEnv;

  if (cpgmuiEnv) {
    requstParameters.cpgmuiEnv = cpgmuiEnv.toLowerCase();
  }
};

const parseToken = (token: string) => {
  const tokenArray = token.split(".");
  let bufferObj = Buffer.from(tokenArray[1], "base64");

  //Encode the Buffer as a utf8 string
  let decodedString = bufferObj.toString("utf8");
  let jsonObject = JSON.parse(decodedString);
  let jti = jsonObject.jti;
  let lastFourDigits = jsonObject.content.paymentInformation.card.number.maskedValue.replaceAll("X", "");
  let bin = jsonObject.content.paymentInformation.card.number.bin;

  return { jti, lastFourDigits, bin };
};

const getCreateTokenError = (err: any) => {
  let msg: Array<string> = [];
  switch (err.reason) {
    case "CREATE_TOKEN_NO_FIELDS_LOADED":
      msg.push("Token creation error, no fields have been loaded." + err.message);
      break;
    case "CREATE_TOKEN_TIMEOUT":
      msg.push("createToken call was unable to proceed." + err.message);
      break;
    case "CREATE_TOKEN_XHR_ERROR":
      msg.push("Network error when attempting to create a token." + err.message);
      break;
    case "CREATE_TOKEN_NO_FIELDS":
      msg.push("Data fields are unavailable for collection." + err.message);
      break;
    case "CREATE_TOKEN_VALIDATION_PARAMS":
      msg.push("Issue with parameters supplied to createToken." + err.message);
      break;
    case "CREATE_TOKEN_VALIDATION_FIELDS":
      // @ts-ignore
      if (err.details && err.details.length > 0) {
        // @ts-ignore
        if (err.details.length > 1) {
          msg.push(INVALIDCARDMESSAGE);
          msg.push(INVALIDCVVMESSAGE);
          // @ts-ignore
        } else if (err.details[0].location === "number") {
          msg.push(INVALIDCARDMESSAGE);
          // @ts-ignore
        } else if (err.details[0].location === "securityCode") {
          msg.push(INVALIDCVVMESSAGE);
        } else {
          msg.push(err.message);
        }
      } else {
        msg.push(err.message);
      }
      break;
    case "CREATE_TOKEN_VALIDATION_SERVERSIDE":
      msg.push("Server-side validation rejects the createToken request." + err.message);
      break;
    case "CREATE_TOKEN_UNABLE_TO_START":
      msg.push("Loaded field was able to handle the createToken request." + err.message);
      break;
    default:
      msg.push("Unknown error:" + err.message);
      break;
  }
  return msg;
};

export {
  classNames,
  getIframeUrl,
  extractRequestParameters,
  getCardType,
  loadCybersourceJS,
  parseToken,
  getCreateTokenError,
  extractDomain,
  extractClientLibrary,
};
